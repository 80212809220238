import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { TranslatePipe } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { WindowMessagesService, WorkerStateService } from '../../../../../core/services';
import { BrxAlertLabelComponent } from '../../../../../ui-components/brx/alert-label/brx-alert-label.component';

import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { BrxLoaderFullscreenComponent } from '../../../../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';

@Component({
  selector: 'app-prices-calendar',
  templateUrl: './prices-calendar.component.html',
  styleUrls: ['./prices-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BrxLoaderFullscreenComponent, BrxAlertLabelComponent],
})
export class PricesCalendarComponent implements OnInit, ContentCreatorItemComponent {
  private readonly workerStateService = inject(WorkerStateService);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly windowMessagesService = inject(WindowMessagesService);
  private readonly translatePipe = inject(TranslatePipe);
  private readonly httpClient = inject(HttpClient);
  private readonly destroyRef = inject(DestroyRef);

  @Input() public tourId: string;
  @Input() public tourContent: TourContent;

  public calendarUrl: string;
  public changedCalendarUrl: string;
  public htmlCalendarUrl: SafeResourceUrl;

  public isLoading = signal(true);
  public errorMessage = signal<string | null>(null);
  public isCalendarFrameVisible = computed(() => !this.isLoading() && this.errorMessage() === null);

  @ViewChild('frame', { read: ViewContainerRef, static: false }) frame: ViewContainerRef;

  ngOnInit(): void {
    this.calendarUrl =
      `https://ht.kz/tours/tour/pricesCalendarScreenshot?tourId=${this.tourId}` +
      `&onlyRealTimePrices=1&datesCount=7&minimumDatesToGenerate=1` +
      `&brandId=${this.workerStateService.currentWorkerValue.brandId}`;
    // При изменении URL фрейма с календарем, обновляем его.
    // Когда менеджер будет кликать по датам он будет меняться
    this.windowMessagesService.messages$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(message => message?.place === 'prices-calendar-screenshot'),
      )
      .subscribe(message => {
        this.isLoading.set(false);
        if (message.error) {
          this.errorMessage.set(
            this.translatePipe.transform('PAGES.CONTENT_CREATOR.CONTENT_ITEMS.PRICES_CALENDAR.NOT_FOUND'),
          );
          return;
        }
        this.changedCalendarUrl = message.href;
      });
    this.htmlCalendarUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.calendarUrl}&format=html&allowChangeDateAndNight=1&width=200`,
    );
  }

  onFrameLoad(): void {
    // Если форма загружена, но не пришло сообщение через windowMessagesService и загрузка не снялась,
    // то значит произошла ошибка, через 3 секунды сами уберем загрузку и покажем сообщение об ошибке
    setTimeout(() => {
      if (this.isLoading()) {
        this.isLoading.set(false);
        this.errorMessage.set(
          this.translatePipe.transform('PAGES.CONTENT_CREATOR.CONTENT_ITEMS.PRICES_CALENDAR.NOT_FOUND'),
        );
      }
    }, 3000);
  }

  /**
   * Загружает содержимое файла по URL для дальнейшей отправки
   * @param name
   * @param url
   * @private
   */
  private getFileFromUrl(name: string, url: string): Observable<File> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      switchMap(blob => {
        return from(Promise.resolve(new File([blob], name, { type: blob.type })));
      }),
    );
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    if (this.errorMessage()) {
      return of([] as ContentCreatorItemMessage[]);
    }

    // Надо удалить из URL ненужные параметры, которые используются для предпросмотра
    const url = new URL(this.changedCalendarUrl);
    const params = new URLSearchParams(url.search);
    params.delete('allowChangeDateAndNight');
    params.delete('format');
    params.set('width', '886');
    url.search = params.toString();

    return this.getFileFromUrl('prices-calendar.png', url.toString()).pipe(
      map(file => {
        return [
          {
            component: this,
            message: { fileContent: file },
          },
        ];
      }),
    );
  }
}
