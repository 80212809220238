import { httpResource } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { AiGptPrompt } from '@api-clients/api-client';
import { first } from 'rxjs/operators';
import { AppConfig } from '../../../../../../../../environments/environment';
import { BrxIconComponent } from '../../../../../../../ui-components/brx/icon/brx-icon.component';
import { GptPromptFormComponent } from '../gpt-prompt-form/gpt-prompt-form.component';

@Component({
  selector: 'app-gpt-prompt-list',
  standalone: true,
  templateUrl: './gpt-prompt-list.component.html',
  styleUrl: './gpt-prompt-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BrxIconComponent, MatTooltip],
})
export class GptPromptListComponent {
  private readonly matDialog = inject(MatDialog);
  private readonly destroyRef = inject(DestroyRef);

  public isDialogMode = input(false);
  public setPrompt = output<AiGptPrompt>();

  public savedPromptVariants = httpResource<AiGptPrompt[]>(
    () => `${AppConfig.commonApiUrl}/ai-gpt-prompt/list`,
  );
  public simplePromptVariants = signal<AiGptPrompt[]>([
    {
      name: 'Продающий текст',
      text: 'Придумай продающий текст',
    },
    {
      name: 'Ответь клиенту',
    },
    {
      name: 'Ответь на возражение',
    },
    {
      name: 'Убеди купить',
      text: 'Убеди клиента купить',
    },
    {
      name: 'Плюсы и минусы отеля',
      text:
        'Проанализируй отзывы с booking.com, tripadvisor.ru и tophotels.ru по этому отелю и выдели пять ' +
        'самых популярных плюсов и три минуса. Пиши кратко, не упоминай про питание и сервис.\n\n' +
        'Пиши в формате:\nПлюсы:\n- ...\n\nМинусы:\n- ...',
    },
    {
      name: 'Фишки отеля',
      text:
        'Проанализируй отзывы с booking.com, tripadvisor.ru и tophotels.ru по этому отелю и ' +
        'напиши кому он подойдет. Простыми словами как другу, но на ВЫ. ' +
        'Важно кратко и по факту упоминать фишки отеля',
    },
    {
      name: 'Орфография',
      text: 'Исправь ошибки правописания в тексте',
    },
  ]);
  public dialogPromptVariants = signal<AiGptPrompt[]>([
    {
      name: 'Оцени диалог',
      text:
        'Оцени диалог между менеджером и клиентом по 100 бальной шкале. ' +
        'Если оценка ниже 100, то дай рекомендации что сделать чтобы было 100',
    },
    {
      name: 'Резюме клиента',
      text: 'На основе представленной информации составь краткое резюме клиента. И выдели основные моменты',
    },
    {
      name: 'Краткий пересказ',
      text: 'Перескажи в краткой форме суть диалога между менеджером и клиентом. И выдели основные моменты',
    },
    {
      name: 'Ответь клиенту',
      text: 'Ответь клиенту на основе представленного диалога',
    },
    {
      name: 'Ответь на возражение',
      text: 'Ответь на возражение клиента на основе представленного диалога',
    },
    {
      name: 'Убеди купить',
      text: 'Убеди клиента купить на основе представленного диалога',
    },
  ]);
  public promptVariants = computed(() => {
    const savedPromptVariants = this.savedPromptVariants.value() ?? [];
    if (this.isDialogMode()) {
      return [...savedPromptVariants, ...this.dialogPromptVariants()];
    } else {
      return [...savedPromptVariants, ...this.simplePromptVariants()];
    }
  });

  addPrompt(): void {
    this.matDialog
      .open(GptPromptFormComponent, {
        panelClass: 'modal-panel-rounded',
        width: '686px',
        minHeight: '250px',
        maxHeight: '95vh',
      })
      .afterClosed()
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe((prompt: AiGptPrompt) => {
        if (prompt) {
          this.savedPromptVariants.set([prompt, ...this.savedPromptVariants.value()]);
        }
      });
  }

  editPrompt(prompt: AiGptPrompt, event: MouseEvent): void {
    event.stopPropagation();
    this.matDialog
      .open(GptPromptFormComponent, {
        panelClass: 'modal-panel-rounded',
        width: '686px',
        minHeight: '250px',
        maxHeight: '95vh',
        data: prompt,
      })
      .afterClosed()
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe((updatedPrompt: AiGptPrompt) => {
        if (updatedPrompt) {
          const variants = this.savedPromptVariants.value() ?? [];
          const index = variants.findIndex(p => p.id === prompt.id);
          // Если промпт удалили
          if (updatedPrompt.name === 'deleted') {
            variants.splice(index, 1);
          } else {
            variants[index] = updatedPrompt;
          }
          this.savedPromptVariants.set([...variants]);
        }
      });
  }
}
