<div class="logo">
  @if (isDevVersion) {
    <div class="dev-logo" (click)="changeVersionToProduction()" title="Перейти на prod версию">
      <img src="assets/img/logo-dev.svg" alt="logo" />
      <span class="label-dev">DEV</span>
    </div>
  } @else {
    <img
      src="assets/img/logo.svg"
      width="32"
      height="32"
      alt="logo"
      fetchPriority="high"
      (load)="isLogoLoaded.set(true)"
    />
  }
</div>

<div class="top-nav left-nav">
  <div class="nav-item border-left"></div>
  @if (isChatsEnabled()) {
    <div class="nav-item" [class.active]="isChatsRoute()">
      <a routerLink="/chats">Чаты</a>
    </div>
  }
  <div class="nav-item" [class.active]="isDealsRoute()">
    <a routerLink="/deals">Сделки</a>
  </div>
  <div class="nav-item">
    <a (click)="showManagersList()">
      {{ 'LAYOUT.TOP.MENU.MANAGERS' | translate }}
    </a>
  </div>
  <div class="nav-item">
    <a (click)="tourDBOpened()" libExternalLink [externalURL]="adminUrl + '/TourDb?active'">
      {{ 'LAYOUT.TOP.MENU.TP_LIST' | translate }}
    </a>
  </div>
  <div class="nav-item">
    <app-network-status />

    @if (isChatsEnabled() && showCallIsland()) {
      <app-calling-island
        style="margin-left: 195px"
        [crmCardViewItem]="currentCallCrmCardItem()"
        (close)="closeCallIsland()"
      />
    }
  </div>
</div>

<nav class="top-nav">
  <div class="nav-item">
    <app-search-form />
  </div>

  <div class="nav-item border-left padding-right">
    <app-commission matTooltip="Ваша комиссия" />
  </div>

  <div class="nav-item border-left">
    <app-worker-status />
  </div>

  <div class="nav-item">
    <div class="icon clickable" (click)="showNotificationsPopup()">
      <img src="assets/icons/top-menu/notifications.svg" alt="notifications" />
      <div class="count">
        <app-notifications-center-count />
      </div>
    </div>
  </div>

  <div class="nav-item">
    <button type="button" class="btn-profile" (click)="toggleMenu()">
      <div class="icon">
        <img src="assets/icons/top-menu/right-side-menu/worker-avatar.svg" alt="avatar" />
      </div>
      <div class="icon arrow" [ngClass]="{ rotate: (popupOpen$ | async) }">
        <img src="assets/icons/top-menu/right-side-menu/arrow-down.svg" alt="arrow" />
      </div>
    </button>
  </div>
</nav>
