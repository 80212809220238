import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';
import { PopupService } from '../../../../shared/services/popup-service.service';
import { DreamsComponent } from '../../../dreams/dreams.component';
import { SettingsComponent } from '../../../settings/components/settings/settings.component';
import { RightSideMenuItemComponent } from './components/right-side-menu-item/right-side-menu-item.component';
import { RightSideMenuItem } from './right-side-menu-item';

@Component({
  selector: 'app-right-side-menu',
  templateUrl: './right-side-menu.component.html',
  styleUrls: ['./right-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClickOutsideDelayOutsideDirective, RightSideMenuItemComponent],
})
export class RightSideMenuComponent {
  private readonly matDialog = inject(MatDialog);
  private readonly popupService = inject(PopupService);

  public menuItems: RightSideMenuItem[] = [
    {
      icon: 'call-list',
      label: 'LAYOUT.ASIDE.CALL_LIST',
      link: '/call-list',
    },
    {
      icon: 'recommendations',
      label: 'LAYOUT.ASIDE.RECOMMENDATIONS',
      link: '/recommendations',
    },
    {
      icon: 'zenmode-list',
      label: 'LAYOUT.ASIDE.DREAMS',
      callback: () => {
        this.matDialog.open(DreamsComponent, {
          panelClass: 'modal-panel-rounded',
          width: '900px',
          minHeight: '350px',
          maxHeight: '95vh',
        });
      },
    },
    {
      icon: 'settings',
      label: 'LAYOUT.ASIDE.SETTINGS',
      callback: () => {
        this.matDialog.open(SettingsComponent, {
          panelClass: 'modal-panel-rounded',
        });
      },
    },
    {
      icon: 'logout',
      label: 'LAYOUT.ASIDE.LOGOUT',
      link: '/logout',
    },
  ];

  close() {
    this.popupService.closeAllModals();
  }
}
