import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AppConfig } from '../../environments/environment';
import { AuthService, WorkerStateService } from '../core/services';

/**
 * Добавляет ко всем запросам к API JWT токен
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly authenticationService = inject(AuthService);
  private readonly workerStateService = inject(WorkerStateService);
  public isLoggedIn: boolean;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.workerStateService.hasAccess$.subscribe(is => {
      this.isLoggedIn = is;
    });
    const isApiUrl =
      req.url.startsWith(AppConfig.apiUrl) ||
      req.url.startsWith(AppConfig.commonApiUrl) ||
      req.url.startsWith(AppConfig.crmApiUrl) ||
      req.url.startsWith(AppConfig.authApiUrl) ||
      req.url.startsWith(AppConfig.searchToursUrl) ||
      req.url.startsWith(AppConfig.andromedaApiUrl);

    if (this.isLoggedIn && isApiUrl) {
      let token = this.useEmployeeToken(req.url)
        ? this.authenticationService.employeeToken
        : this.authenticationService.token;
      if (this.useRealToken(req.url)) {
        token = this.authenticationService.realToken;
      }
      let url = req.url;
      if (!AppConfig.production) {
        const separator = req.url.includes('?') ? '&' : '?';
        url = `${req.url}${separator}XDEBUG_SESSION=1`;
      }
      req = req.clone({
        url,
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(req);
  }

  isAndromedaUrl(url: string): boolean {
    return url.startsWith(AppConfig.andromedaApiUrl);
  }

  useEmployeeToken(url: string): boolean {
    return (
      this.isAndromedaUrl(url) ||
      url.startsWith(AppConfig.commonApiUrl + '/manager-search/calculate-sale-price') ||
      url.startsWith(AppConfig.commonApiUrl + '/manager-search/view-hotel') ||
      url.startsWith(AppConfig.commonApiUrl + '/manager-search/tour-tickets-count') ||
      url.startsWith(AppConfig.authApiUrl + '/employee/refresh-token')
    );
  }

  useRealToken(url: string): boolean {
    // Список для переключения на других пользователей
    // нам нужно получать с реальным токеном исходного пользователя
    return url.startsWith(AppConfig.authApiUrl + '/user/list-for-login-proxy');
  }
}
