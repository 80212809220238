import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  pure: true,
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchQuery: string): string {
    if (!searchQuery) {
      return value;
    }

    // Экранируем специальные символы в поисковом запросе
    const escapedQuery = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedQuery, 'gi');
    const matches = [];

    // Находим все совпадения и сохраняем их позиции
    let match = regex.exec(value);
    while (match !== null) {
      matches.push({
        start: match.index,
        end: match.index + match[0].length,
      });
      match = regex.exec(value);
    }

    if (matches.length === 0) {
      return value;
    }

    // Собираем результирующую строку с подсветкой
    let result = '';
    let lastIndex = 0;

    for (const m of matches) {
      // Добавляем текст до совпадения
      result += value.slice(lastIndex, m.start);
      // Добавляем выделенное совпадение (с оригинальным регистром)
      result += `<mark>${value.slice(m.start, m.end)}</mark>`;
      lastIndex = m.end;
    }

    // Добавляем оставшийся текст после последнего совпадения
    result += value.slice(lastIndex);

    return result;
  }
}
