import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, retry, switchMap, tap, timer } from 'rxjs';
import { ChatApiService } from '../../core/services/chat/chat-api.service';
import { MessageSyncService } from '../../modules/chat-timeline/services/message-sync.service';
import {
  ActionResultStub,
  ChatMessagesActionTypes,
  DeleteMessage,
  DeleteMessageFailure,
  DeleteMessageSuccess,
  EditMessage,
} from './chat-messages.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ChatMessagesEffects {
  deleteMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteMessage>(ChatMessagesActionTypes.DeleteMessage),
      switchMap(({ payload }) =>
        this.chatApiService.deleteMessage(payload).pipe(
          tap(() => {
            this.messageSyncService.notifyMessageDeleted(payload.messageId);
          }),
          map(() => new DeleteMessageSuccess(payload.messageId)),
          catchError(error => of(new DeleteMessageFailure(error))),
        ),
      ),
    ),
  );

  editMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EditMessage>(ChatMessagesActionTypes.EditMessage),
      switchMap(({ payload }) => {
        // todo: optimistic обновление, и в случае ошибки - откатить
        // const prevState = ...
        return this.chatApiService.editMessage(payload).pipe(
          retry({
            count: 1,
            delay: (error: Error) => {
              if (error instanceof HttpErrorResponse && (error.status > 500 || error.status === 0)) {
                // 502/504 и обрыв соединения
                return timer(500);
              } else {
                throw error; // Прерываем retry
              }
            },
          }),
          // обновление чата черезе сокет, кидаем заглушки
          map(response => {
            if (!response.success) {
              this.notifyMessageEditeFailed(response.message);
            }

            return new ActionResultStub({
              action: 'EditMessage',
              message: response.success ? 'success' : 'failed',
            });
          }),
          catchError(err => {
            console.error('editMessage error', err);
            this.notifyMessageEditeFailed();
            return of(new ActionResultStub({ action: 'EditMessage', message: 'failed' }));
          }),
        );
      }),
    ),
  );

  protected notifyMessageEditeFailed(message?: string) {
    this.messageSyncService.notifyMessageEditFailed(message || 'Не удалось отредактировать сообщение');
  }

  constructor(
    private actions$: Actions,
    private chatApiService: ChatApiService,
    private messageSyncService: MessageSyncService,
  ) {}
}
