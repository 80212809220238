import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { WorkerLoginRequest } from '@api-clients/api-client/dist/models';
import { TranslateModule } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AppInitService, AuthService } from '../../../../core/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatFormField,
    MatInput,
    MatButton,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
})
export class LoginComponent {
  private readonly fb = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly authenticationService = inject(AuthService);
  private readonly appInitService = inject(AppInitService);

  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  errorMessage = signal<string>('');
  isLoading = signal<boolean>(false);

  get formControls() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading.set(true);
    this.errorMessage.set('');

    const workerLoginRequest: WorkerLoginRequest = {
      username: this.formControls.username.value,
      password: this.formControls.password.value,
    };

    this.authenticationService
      .login(workerLoginRequest)
      .pipe(first())
      .subscribe({
        next: () => {
          this.appInitService.init().then(() => {
            this.router.navigate(['/']).then(r => r);
          });
        },
        error: errorResponse => {
          this.isLoading.set(false);
          if (errorResponse.statusCode === 422 && errorResponse.errors) {
            this.errorMessage.set(errorResponse.errors.map((err: any) => err.errors.join(', ')).join('; '));
          } else {
            this.errorMessage.set('Произошла ошибка при входе');
          }
        },
      });
  }
}
