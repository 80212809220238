import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';

@Injectable()
export class AppErrorHandler extends SentryErrorHandler {
  constructor() {
    super({ logErrors: true });
  }

  handleError(error: any): void {
    const chunkFailedMessage = 'Failed to fetch dynamically imported module';
    if (chunkFailedMessage.includes(error.message)) {
      if (confirm('Доступна новая версия. Для корректной работы необходимо обновится')) {
        window.location.reload();
      }
    }

    super.handleError(error);
  }
}
