@if (isLoading$ | async) {
  <brx-loader-fullscreen />
}
<brx-scrollable-wrapper cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <brx-modal-header cdkDragHandle [text]="header" [dialogRef]="dialogRef" />
  <div
    class="content-creator-wrapper"
    [class.visible]="isContentCreatorWrapperVisible$ | async"
    [class.single-message-input-visible]="isSingleMessageInputVisible"
  >
    @if (hotelName$ | async; as hotelName) {
      <brx-alert-label type="warning">
        {{ hotelName }}
      </brx-alert-label>
    }
    @if (isSingleContent()) {
      <div brxScrollableTarget>
        <div #itemWrapperComponentsPlace class="item-wrapper-components-place"></div>
      </div>
    } @else {
      <div #scrollableWrapper class="scrollable-wrapper" brxScrollableTarget>
        <div #itemWrapperComponentsPlace class="item-wrapper-components-place"></div>
      </div>
    }
    @if (isSingleMessageInputVisible) {
      <div class="single-message">
        <textarea
          #singleMessageTextarea
          class="single-message-textarea"
          [(ngModel)]="singleMessageText"
          rows="1"
          spellcheck="true"
          appResizableTextarea
          [maxHeightInPx]="250"
          appSpeechRecognition
          [screenType]="screenType"
          matInput
          placeholder="{{ 'PAGES.CONTENT_CREATOR.MESSAGE_PLACEHOLDER' | translate }}"
        ></textarea>
        <button
          brx-button
          color="blue"
          [iconName]="isOpenedFromPlaceholder ? null : '@brx.send-message'"
          class="btn-send-content"
          (click)="sendContent()"
        >
          @if (isOpenedFromPlaceholder) {
            Добавить
          }
        </button>
      </div>
    }
    @if (isPlainSendButtonVisible) {
      <div class="message-send-controls">
        <button
          brx-button
          color="blue"
          iconName="@brx.send-message"
          class="btn-send-content-big"
          (click)="sendContent()"
        >
          Отправить
        </button>
      </div>
    }
  </div>
  @if (errorMessage$ | async; as errorMessage) {
    <brx-alert-label type="danger">
      {{ errorMessage }}
    </brx-alert-label>
  }
</brx-scrollable-wrapper>
