import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SipStateListEvent } from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AuthService, WorkerStateService } from '../../../../core/services';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { DEAL_VIEW_OPENED } from '../../../../core/services/amplitude/amplitudeEvents';
import { AuthApiService } from '../../../../core/services/auth/auth-api.service';
import { Worker } from '../../../../models';
import { FeatureId } from '../../../../shared/services/feature-id.enum';
import { IntroManagementService } from '../../../../shared/services/intro-management.service';
import { BrxAlertLabelComponent } from '../../../../ui-components/brx/alert-label/brx-alert-label.component';
import { BrxButtonComponent } from '../../../../ui-components/brx/button/brx-button.component';
import { CloseModalButtonComponent } from '../../../../ui-components/close-modal-button/close-modal-button.component';
import { BrxInputSearchComponent } from '../../../../ui-components/brx/input-search/brx-input-search.component';
import { BrxLoaderFullscreenComponent } from '../../../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';
import { convertToRussianLayout } from '../../../deals/modules/deal-view/modules/search-tours/functions/filter-hotels-by-search-query.functions';
import { WorkerNameComponent } from './components/worker-name/worker-name.component';
import { WorkersListService } from './services/workers-list.service';

const CALL_CENTER_ROLE = 'call_operator';

@Component({
  selector: 'app-workers-list',
  templateUrl: './workers-list.component.html',
  styleUrls: ['./workers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslateModule,
    WorkerNameComponent,
    BrxLoaderFullscreenComponent,
    BrxInputSearchComponent,
    CloseModalButtonComponent,
    BrxAlertLabelComponent,
    BrxButtonComponent,
  ],
})
export class WorkersListComponent implements OnInit {
  public dialog = inject(MatDialog);
  public dialogRef = inject(MatDialogRef<WorkersListComponent>);
  public workersListService = inject(WorkersListService);
  private readonly workerStateService = inject(WorkerStateService);
  private readonly amplitudeTrackService = inject(AmplitudeTrackService);
  private readonly introManagementService = inject(IntroManagementService);
  private readonly authService = inject(AuthService);
  private readonly authApiService = inject(AuthApiService);
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);

  public currentWorker = this.workerStateService.currentWorkerValue;
  public workersList = signal<Worker[]>([]);
  public allowedProxyUserIds = signal<Set<number>>(new Set());
  public searchQuery = signal<string>('');
  public russianLayoutSearchQuery = computed<string>(() => {
    return convertToRussianLayout(this.searchQuery());
  });
  public filteredWorkersList = computed<Worker[]>(() => {
    return this.workersList().filter(worker => {
      const lowerName = worker.name.toLowerCase();
      const searchQuery = this.searchQuery().toLowerCase();
      return (
        lowerName.includes(searchQuery) ||
        lowerName.includes(this.russianLayoutSearchQuery().toLowerCase()) ||
        worker.sip.innerPhone.toString().includes(searchQuery)
      );
    });
  });
  public workersByOffice = computed<{ [key: string]: Worker[] }>(() => {
    return this.filteredWorkersList().reduce(
      (result, currentValue) => {
        const officeName = currentValue.role === CALL_CENTER_ROLE ? 'Колл-центр' : currentValue.office.name;
        if (!result[officeName]) {
          result[officeName] = [];
        }
        result[officeName].push(currentValue);
        return result;
      },
      {} as { [key: string]: Worker[] },
    );
  });
  public officeList = computed<string[]>(() => {
    return Object.keys(this.workersByOffice()).filter(office => {
      return this.workersByOffice()[office].length > 0;
    });
  });

  public screenType = ScreenTypes.WORKERS_LIST;

  isProxyAuth = this.authService.isProxyAuth;
  isLoading = signal(true);
  proxyLoginLoadingUserId = signal<number>(null);

  ngOnInit(): void {
    this.authApiService
      .getUsersForProxy()
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(users => {
        this.allowedProxyUserIds.set(new Set(users.list.map(user => user.id)));
      });

    this.workersListService
      .getList(this.currentWorker.cityId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(workersList => {
        this.isLoading.set(false);
        this.workersList.set(workersList);

        this.workersListService.sipState$
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((sipStates: SipStateListEvent) => {
            if (sipStates) {
              this.updateSipStates(sipStates);
            } else {
              // Если вы не подключены к prod сокету, это норма!
              console.warn('sipStates is null. check ws calls.getCurrentSipState()');
            }
          });

        setTimeout(() => {
          this.introManagementService.queueIntro(FeatureId.WORKER_LIST_CARD, false, [], null);
        }, 500);
      });
  }

  public onSearchQueryChange(query: string): void {
    this.searchQuery.set(query);
  }

  updateSipStates(sipStates: SipStateListEvent): void {
    const workers = [...this.workersList()];
    workers.forEach((worker: Worker) => {
      sipStates.list.forEach(workerState => {
        if (workerState.sip === worker.sip.innerPhone) {
          worker.state = workerState.state;
        }
      });
    });
    this.workersList.set(workers);
  }

  loginProxy(userId: number): void {
    this.proxyLoginLoadingUserId.set(userId);
    this.authService
      .loginProxy(userId)
      .pipe(
        catchError(() => {
          this.proxyLoginLoadingUserId.set(null);
          alert('Не удалось переключиться на пользователя');
          return EMPTY;
        }),
      )
      .subscribe(() => {
        window.location.reload();
      });
  }

  logoutProxy(): void {
    this.isLoading.set(true);
    this.authService.logoutProxy();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public sendMessage(worker: Worker): void {
    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, {
      isManagerCard: true,
      screenType: this.screenType,
    });
    this.router.navigate(['/deals/view', worker.crmCardId]);
    this.closeModal();
  }
}
