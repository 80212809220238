<div class="prompt-variants">
  <div class="prompt-variant user-defined" (click)="addPrompt()">
    <brx-icon name="@brx.plus" /> Добавить...
  </div>
  @for (promptVariant of promptVariants(); track promptVariant) {
    <div class="prompt-variant" (click)="setPrompt.emit(promptVariant)">
      {{ promptVariant.name }}
      @if (promptVariant.id) {
        <brx-icon
          class="edit-prompt"
          name="@brx.pencil"
          [size]="12"
          (click)="editPrompt(promptVariant, $event)"
          matTooltip="Редактировать"
        />
      }
    </div>
  }
</div>
