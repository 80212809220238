import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { StandaloneTaskItem } from '@api-clients/crm-api-client';
import { CrmCardListItem } from '@api-clients/crm-api-client/dist/models';
import * as Sentry from '@sentry/angular';
import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AppConfig } from '../environments/environment';
import { DealsFacade } from './+state/deals/deals.facade';
import { RecommendationsFacade } from './+state/recommendations/recommendations.facade';
import { SettingsFacade } from './+state/settings/settings.facade';
import { WhatsappContactsFacade } from './+state/whatsapp-contacts/whatsapp-contacts.facade';
import { AuthService, WebsocketService, WorkerStateService } from './core/services';
import { NavigationStateService } from './core/services/+state/navigation-state.service';
import { NewVersionCheckerService } from './core/services/new-version-checker.service';
import { DealStatus } from './models/deal-status';
import { TaskItem } from './models/ipcEvent';
import { ContentCreatorModalService } from './modules/content-creator/services/content-creator-modal.service';
import { FeatureToggleService } from './modules/deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { DealItemsCounterService } from './modules/deals/services/deal-items-counter.service';
import { DealTasksService } from './modules/deals/services/deal-tasks.service';
import { DreamMotivationsModalService } from './modules/dreams/services/dream-motivations-modal.service';
import { HotOffersService } from './modules/hot-offers/hot-offers.service';
import { RecommendationsCounterService } from './modules/recommendations/components/services/recommendations-counter.service';
import { GoogleMapsLoaderService } from './modules/search/services/google-maps-loader.service';
import { SearchApiService } from './modules/search/services/search/search-api.service';
import { ChatsCounterService } from './modules/whatsapp/services/chats-counter.service';
import { NotifyComponent } from './shared/notify/notify.component';
import { BrxLoaderFullscreenComponent } from './ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';
import { ProxyAuthFrameComponent } from './ui-components/proxy-auth-frame/proxy-auth-frame.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BrxLoaderFullscreenComponent, RouterOutlet, NotifyComponent, AsyncPipe, ProxyAuthFrameComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly navStateService = inject(NavigationStateService);
  private readonly whatsappContactsFacade = inject(WhatsappContactsFacade);
  private readonly featureToggleService = inject(FeatureToggleService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly swUpdate = inject(SwUpdate);
  private readonly hermesNewVersionCheckerService = inject(NewVersionCheckerService);
  private readonly authService = inject(AuthService);
  private readonly websocketService = inject(WebsocketService);

  public deals$: Observable<CrmCardListItem[]> = this.dealsFacade.dealsList$;
  public tasks$: Observable<TaskItem[]>;

  public unreadChats$: Observable<number> = this.whatsappContactsFacade.unreadChats$;
  public recommendationsList$: Observable<StandaloneTaskItem[]> =
    this.recommendationsFacade.recommendationsList$;
  // поменял на сигнал - перестал скрываться после редиректа на /login, повисает на вечно!
  // вернул Subject
  public isLoading: Subject<boolean> = new Subject<boolean>();
  public isInitialLoading = signal(true);
  public isProxyAuth = this.authService.isProxyAuth;

  private subscriptionHotOffersInit: Subscription;
  private subscriptionHotOffersInitTimer: Subscription;
  private searchFormParamsTimer: Subscription;
  private lastActivityAt: Date | null = null;
  private checkActivityDisabledTimer: ReturnType<typeof setTimeout>;

  constructor(
    private dealsFacade: DealsFacade,
    private recommendationsFacade: RecommendationsFacade,
    private settingsFacade: SettingsFacade,
    private dealItemsCounterService: DealItemsCounterService,
    private dealTasksService: DealTasksService,
    private chatsCounterService: ChatsCounterService,
    private workerStateService: WorkerStateService,
    private recommendationsCounterService: RecommendationsCounterService,
    private contentCreatorModalService: ContentCreatorModalService,
    private dreamMotivationsModalService: DreamMotivationsModalService,
    private router: Router,
    private readonly mapsLoader: GoogleMapsLoaderService,
    private readonly hotOffersService: HotOffersService,
    private readonly searchApiService: SearchApiService,
  ) {
    this.isLoading.next(true);
    this.router.events.subscribe(e => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit(): void {
    if (AppConfig.environment === 'PROD' || AppConfig.environment === 'STAGE') {
      console.warn({
        version: Sentry.getClient().getOptions().release,
        swUpdate: this.swUpdate.isEnabled,
      });
    }

    this.contentCreatorModalService.listen();

    if (this.workerStateService.accessStateValue) {
      // Редиректы срабатывают с задержкой, а при init snap = ''
      // const isChatsRoute = this.navStateService.isChatsRoute(this.router.routerState.snapshot);

      // TODO: проверить, насколько критично показать задания в окне уведомлений при старте в разделе "чаты"
      // //  Может зацепить только "sendTasksToNotifications".
      // //  - НЕ покажется "summary" по заданиям в окне уведомлений, у пользователей с чатами.
      // //  Если потребуется - просто включите как было: всегда загружать сделки!
      // const isChatsRouteDefault = this.isChatsRouteDefault();

      this.initHighestPriorityGeneralHandlers();
      this.initHighestPriorityDealsHandlersForDeals(false);
    }

    this.initCurrentWorkerHandlersWithTimers();
    this.authHandlers();

    // lowest priority
    try {
      void this.mapsLoader.loadApi().then(() => {});
    } catch (e) {
      // не смогли загрузить, то ничего не делаем, дальше будет проверка в UI на доступность карт
    }
  }

  ngOnDestroy(): void {
    this.subscriptionHotOffersInitTimer?.unsubscribe();
    this.searchFormParamsTimer?.unsubscribe();
  }

  @HostListener('document:mousemove')
  onMouseMove() {
    this.checkActivity();
  }

  private authHandlers(): void {
    this.authService.logout$.subscribe(() => {
      this.websocketService.closeWebsocketConnection();
    });
  }

  private checkActivity(): void {
    // Проверка активности срабатывает только раз в пять секунд, чтобы слишком часто не дергать
    const isTimerExists = !!this.checkActivityDisabledTimer;
    clearTimeout(this.checkActivityDisabledTimer);
    this.checkActivityDisabledTimer = setTimeout(() => {
      this.checkActivityDisabledTimer = null;
    }, 5000);
    if (isTimerExists) {
      return;
    }

    if (!this.workerStateService.currentWorkerValue) {
      return;
    }

    const currentTime = new Date();
    // Показываем мотивацию по мечте при первой активности или возврате после более чем через 5 часов
    if (!this.lastActivityAt || currentTime.getTime() - this.lastActivityAt.getTime() > 60000 * 60 * 5) {
      this.dreamMotivationsModalService.getAndShowMotivation();
    }

    // Обновляем время последней активности
    this.lastActivityAt = currentTime;
  }

  private isChatsRouteDefault(): boolean {
    const worker = this.workerStateService.currentWorkerValue;
    return Boolean(worker) && this.featureToggleService.shouldDisplayChats(worker);
  }

  private initHighestPriorityGeneralHandlers() {
    this.settingsFacade.loadSettings(); // todo: точно ли должен блокировать?
    // Состояние инстанса проверяется в отдельном компоненте scan-qr
    // todo: собрать один state от instanc
    // todo: переделать на SignalStore + rxMethod + switchMap
    //  таймер уже запускать будем отдельно
    this.whatsappContactsFacade.loadInstance(); // todo: загружать фоном

    this.unreadChats$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(chatsCount => {
      this.chatsCounterService.setUnreadChatsCount(chatsCount);
    });

    // Пока хз куда это, но глобально пока не требуется
    if (AppConfig.experimentalRecommendations && this.workerStateService.currentWorkerValue.employeeId) {
      this.recommendationsFacade.loadRecommendations();
      this.recommendationsList$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(list => {
        this.recommendationsCounterService.recommendationsTotalNumber = list.length;
      });
    }
  }

  private initHighestPriorityDealsHandlersForDeals(asyncLoadDeals: boolean): void {
    if (!asyncLoadDeals) {
      // todo: сделать нормально =( Разнести по модулям и прочее
      //  хотя бы добавить fetch priority на лишние запросы
      this.dealsFacade.loadAll();
    }

    this.deals$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(deals =>
          deals.filter(deal => deal.deal.status === DealStatus.new || deal.deal.status === DealStatus.work),
        ),
      )
      .subscribe(deals => {
        this.dealItemsCounterService.dealsTotalNumber = deals.length;
      });

    this.tasks$ = this.deals$.pipe(
      switchMap(deals => {
        const tasks = deals
          .filter(deal => deal.nextTask !== undefined && deal.nextTask !== null)
          .map(deal => ({
            task: deal.nextTask,
            clientName: deal.card.name,
            crmCardId: deal.card.id,
            status: deal.deal.status,
          }));

        return of(tasks);
      }),
    );

    this.tasks$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(list => this.dealTasksService.sendTasksToNotifications(list));
  }

  private initCurrentWorkerHandlersWithTimers(): void {
    this.workerStateService.currentWorker$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        filter(worker => !!worker),
      )
      .subscribe(worker => {
        this.lastActivityAt = null;
        this.checkActivity();

        if (AppConfig.production) {
          Sentry.setUser({ id: worker.id, brandId: worker.brandId, username: worker.username });
        }

        if (this.swUpdate.isEnabled) {
          this.hermesNewVersionCheckerService.subscribeForVersionUpdates();
          interval(60_000)
            .pipe(switchMap(() => this.swUpdate.checkForUpdate()))
            .subscribe();
        }

        this.subscriptionHotOffersInit?.unsubscribe();
        this.subscriptionHotOffersInit = this.hotOffersService.initState$().subscribe();

        this.subscriptionHotOffersInitTimer?.unsubscribe();
        this.subscriptionHotOffersInitTimer = interval(5 * 60 * 1000)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap(() => this.hotOffersService.initState$()),
          )
          .subscribe(() => {
            this.hotOffersService.flushData();
            this.hotOffersService.initState$().subscribe();
          });

        this.searchFormParamsTimer = interval(30 * 60 * 1000)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap(() => this.searchApiService.warmUpSearchFormParamsCache()),
          )
          .subscribe(() => {
            console.log('Параметры формы поиска обновлены');
          });

        this.searchApiService.warmUpSearchFormParamsCache().subscribe();

        interval(120_000)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.authService.checkEmployeeToken();
          });
      });
  }

  private navigationInterceptor(event: any): void {
    this.navStateService.onUrlChange(this.router.routerState.snapshot.url);

    switch (true) {
      case event instanceof NavigationStart: {
        this.isLoading.next(true);
        break;
      }

      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {
        this.isLoading.next(false);
        this.isInitialLoading.set(false);
        break;
      }
      default: {
        break;
      }
    }
  }
}
