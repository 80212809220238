<div class="frame"></div>
<div class="worker-wrapper">
  <div class="worker">
    <div class="rounded-clip">
      <div class="box"></div>
    </div>
    <brx-icon name="@brx.incognito" />
    <div class="worker-name" [matTooltip]="'Вы авторизованы под сотрудником: ' + currentWorker.name">
      {{ currentWorker.name }}
    </div>
    <button
      class="exit-button"
      brx-button
      color="dark-green"
      (click)="logoutProxy()"
      [isLoading]="isLoading()"
    >
      Выйти
    </button>
    <div class="rounded-clip right">
      <div class="box"></div>
    </div>
  </div>
</div>
