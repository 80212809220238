<textarea
  rows="1"
  spellcheck="true"
  appResizableTextarea
  [maxHeightInPx]="257"
  appSpeechRecognition
  [screenType]="screenType"
  [recorderButtonMarginBottom]="3"
  matInput
  [(ngModel)]="message"
></textarea>
