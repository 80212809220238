<div class="section-switcher">
  <div class="section-label" [class.active]="currentStageChatListFilterIsAll()" (click)="setStageFilterAll()">
    @if (hasUnread()) {
      <span class="marker-unread in-section"></span>
    }
    <img width="24" height="24" src="assets/icons/aside-menu/chats-icon.svg" alt="" srcset="" />
    <span class="section-caption">{{ 'LAYOUT.ASIDE.CHATS' | translate }} </span>
  </div>
</div>

<ng-template #itemLink let-stageKey="stageKey">
  @let counter = getConditionalCounter(stageKey);
  <a
    (click)="setStageFilter(stageKey)"
    class="chat-filter"
    [class.active]="isActiveStage(stageKey)"
    [class.muted]="counter.total === 0"
    [class.has-items]="counter.total > 0"
    [attr.data-stage]="stageKey"
    matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + stageKey | translate }}"
    matTooltipPosition="right"
  >
    @if (counter && counter.total > 0) {
      <span class="stage-counter">
        {{ counter.total }}
      </span>
      @if (hasUnreadMarker(counter)) {
        <span class="marker-unread"></span>
      }
    }
  </a>
</ng-template>
<ng-template #itemSubLink let-stageKey="stageKey" let-stageLabel="stageLabel">
  @let counter = getConditionalCounter(stageKey);
  <a
    (click)="setStageFilter(stageKey)"
    class="days-stage"
    [class.active]="isActiveStage(stageKey)"
    [attr.data-stage]="stageKey"
    matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + stageKey | translate }}"
    matTooltipPosition="right"
  >
    <span class="days-stage-label">{{ stageLabel }}</span>
    @if (counter && counter.total > 0) {
      <span class="stage-counter">
        {{ counter.total }}
      </span>
      @if (hasUnreadMarker(counter)) {
        <span class="marker-unread in-submenu"></span>
      }
    }
  </a>
</ng-template>

<div class="aside-chats-filters">
  <div class="aside-chats-filters__item">
    <div class="deal-top-stages-container">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.MISSED_AND_URGENT }"
      />
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.NEW }"
      />
    </div>
    <ng-container
      [ngTemplateOutlet]="itemLink"
      [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.READY_FOR_BOOKING }"
    />
    <div class="stage-with-subfilters" [class.opened]="isDaysFiltersOpened()">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.IN_PROGRESS }"
      />
      @if (isDaysFiltersOpened()) {
        <div class="days-filters">
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.FIRST_DAY, stageLabel: '1 д' }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.SECOND_DAY, stageLabel: '2 д' }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.THIRD_DAY, stageLabel: '3 д' }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{
              stageKey: chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS,
              stageLabel: '4-7 д',
            }"
          />
          <ng-container
            [ngTemplateOutlet]="itemSubLink"
            [ngTemplateOutletContext]="{
              stageKey: chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS,
              stageLabel: '> 7 д',
            }"
          />
        </div>
      }
    </div>
  </div>
  <div class="aside-chats-filters__item aside-chats-filters__item--bottom">
    <div class="aside-chats-filters__item">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.MEETING }"
      />
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.POSTPONED }"
      />
    </div>
    <div class="aside-chats-filters__item">
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.COLD_TOUCH }"
      />
      <ng-container
        [ngTemplateOutlet]="itemLink"
        [ngTemplateOutletContext]="{ stageKey: chatListStageFilterKeys.POST_SALE }"
      />
      <!-- Footer -->
      <div class="add-deal-container">
        <button type="button" class="add-deal-button" (click)="showAddDealPopup()">
          <img src="assets/icons/plus-bold-blue.svg" width="16" height="16" alt="" class="add-deal-icon" />
        </button>
      </div>
      <!-- Footer End -->
    </div>
  </div>
</div>
