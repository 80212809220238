import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AiGptPrompt, AiGptPromptCreateOrUpdateRequest, DefaultResponse } from '@api-clients/api-client/dist';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AiGptPromptApiService {
  private readonly http = inject(HttpClient);

  create(name: string, text: string): Observable<AiGptPrompt> {
    const request: AiGptPromptCreateOrUpdateRequest = {
      name,
      text,
    };
    return this.http.post<AiGptPrompt>(`${AppConfig.commonApiUrl}/ai-gpt-prompt/create`, request);
  }

  update(id: number, name: string, text: string): Observable<AiGptPrompt> {
    const request: AiGptPromptCreateOrUpdateRequest = {
      id,
      name,
      text,
    };
    return this.http.post<AiGptPrompt>(`${AppConfig.commonApiUrl}/ai-gpt-prompt/update`, request);
  }

  delete(id: number): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${AppConfig.commonApiUrl}/ai-gpt-prompt/delete?id=${id}`, {});
  }
}
