import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private libraryLoadedSub = new BehaviorSubject<boolean>(false);

  private apiLoaded = false;
  private readonly loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: AppConfig.googleMapsKey,
      version: '3.60.6',
      libraries: ['geometry', 'places', 'marker', 'geocoding'],
      language: 'ru',
    });
  }

  loadApi(): Promise<void> {
    if (this.apiLoaded) {
      return Promise.resolve();
    }
    return this.loader.importLibrary('maps').then(() => {
      this.apiLoaded = true;
      this.libraryLoadedSub.next(true);
    });
  }
}
