@use '../../../../../assets/styles/abstracts/variables' as *;

$office-shadow: 0 0 9px rgba(0, 0, 0, 0.08);
$office-border-top: #bddfff;
$office-border: #e3e9ee;
$legend-border: #e0e4ea;

#workers-list-component.mat-dialog-container {
  height: auto;
  margin-right: 20px;
  padding: 0;
  border-radius: 4px;
  box-shadow: $office-shadow;
}

brx-input-search {
  width: 400px;
}

.non-clickable {
  cursor: default;
  pointer-events: none;
}

.proxy-login-info .alert-label {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-managers-list {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 22px 27px 0 20px;
  background: var(--white);

  &-title {
    margin-bottom: 30px;
    color: var(--h-gray-800);
    font-size: 22px;
  }

  .managers-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: calc(100% - 60px - 40px);
  }
}

.office-item {
  min-width: 257px;
  height: fit-content;
  margin: 0 30px 30px 0;
  padding: 17px 16px;
  background: var(--white);
  border: 1px solid $office-border;
  border-top: 4px solid $office-border-top;
  border-radius: 4px;
  box-shadow: $office-shadow;

  &-title {
    margin-bottom: 18px;
    color: var(--h-gray-800);
    font-weight: 500;
    font-size: 15px;
  }
}

.managers-status-legend {
  background: inherit;
  position: sticky;
  bottom: 0;

  display: flex;
  margin-top: auto;
  padding: 20px 0;

  border-top: 1px solid $legend-border;

  &-title {
    margin-right: 24px;
  }

  .status-legend-items {
    display: flex;
  }

  .status-legend-item {
    display: flex;
    align-items: center;
    margin-right: 23px;
  }

  .managers-status {
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 14px;
    border-radius: 50%;

    &--available {
      background: $manager-status--available;
    }

    &--busy {
      background: $manager-status--busy;
    }

    &--offline {
      background: $manager-status--offline;
    }
  }

  .status-legend-title {
    color: var(--h-gray-800);
    font-weight: 300;
    font-size: 13px;
  }
}
