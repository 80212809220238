import 'reflect-metadata';

import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { ErrorHandler } from '@angular/core';

// NG Translate
import { MetaReducer } from '@ngrx/store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IConfig } from 'ngx-mask';
import { AppConfig } from '../environments/environment';
import { AppErrorHandler } from './app-error.handler';
import { AppInitService } from './core/services';

registerLocaleData(localeRu);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function configServiceFactory(appInitService: AppInitService) {
  return () => appInitService.init();
}

export const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: true,
  };
};

export const metaReducers: MetaReducer<any>[] = [];

export function errorHandlerFactory() {
  if (AppConfig.production) {
    return new AppErrorHandler();
  }
  return new ErrorHandler();
}
