import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  model,
  OnInit,
  Output,
  Signal,
  signal,
  viewChild,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { AiGptCompleteRequestModelEnum, AiGptMessage, AiGptMessageRoleEnum } from '@api-clients/api-client';
import { AiGptPrompt } from '@api-clients/api-client/dist';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { ChatSendMessageRequest, CrmCardViewItem, WhatsappMessage } from '@api-clients/crm-api-client';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { ChatMessagesFacade } from '../../../../../+state/chat-messages/chat-messages.facade';
import { ChatsDataService } from '../../../../../+state/chats/store/chats.data.service';
import { WorkerStateService } from '../../../../../core/services';
import { ScreenTypes } from '../../../../../core/services/amplitude/amplitudeEventData';
import { pluralize } from '../../../../../helpers/pipes/plural/pluralize';
import { SpeechRecognitionDirective } from '../../../../../shared/directives/speech-recognition.directive';
import { TextSelectionDirective } from '../../../../../shared/directives/text-selection.directive';
import { BrxButtonComponent } from '../../../../../ui-components/brx/button/brx-button.component';
import { BrxCheckboxComponent } from '../../../../../ui-components/brx/checkbox/brx-checkbox.component';
import { BrxIconComponent } from '../../../../../ui-components/brx/icon/brx-icon.component';
import { LineProgressComponent } from '../../../../../ui-components/line-progress/line-progress.component';
import { SpeechBubbleComponent } from '../../../../../ui-components/speech-bubble/speech-bubble.component';
import { ChatTimelineService } from '../../../../chat-timeline/services/chat-timeline.service';
import { DialogueTranscriptService } from '../../../../chat-timeline/services/dialogue-transcript.service';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { AiGptApiService } from '../../../services/ai-gpt-api.service';
import { ResizableTextareaDirective } from '../../../../../shared/directives/resizable-textarea.directive';
import { GptPromptListComponent } from './components/gpt-prompt-list/gpt-prompt-list.component';

@Component({
  selector: 'app-gpt',
  imports: [
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    ResizableTextareaDirective,
    SpeechRecognitionDirective,
    BrxButtonComponent,
    TextSelectionDirective,
    SpeechBubbleComponent,
    BrxIconComponent,
    LineProgressComponent,
    BrxCheckboxComponent,
    MatTooltip,
    GptPromptListComponent,
  ],
  templateUrl: './gpt.component.html',
  styleUrls: ['./gpt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GptComponent implements ContentCreatorItemComponent, OnInit {
  private readonly aiGptApiService = inject(AiGptApiService);
  private readonly workerService = inject(WorkerStateService);
  private readonly chatMessagesFacade = inject(ChatMessagesFacade);
  private readonly chatTimelineService = inject(ChatTimelineService);
  private readonly dialogueTranscriptService = inject(DialogueTranscriptService);
  private readonly chatsFacadeService = inject(ChatsDataService);

  private readonly destroyRef = inject(DestroyRef);

  @Input() public tourId: string;
  @Input() public tourContent: TourContent;
  @Input() public initialMessage: WhatsappMessage;
  @Input() public isContentCreatorInitialized$: Observable<boolean>;
  @Output() public sendContentEvent = new EventEmitter();
  @Output() public sendMessageEvent = new EventEmitter<Partial<ChatSendMessageRequest>>();

  public initialText = model('');
  public prompt = '';

  public completionText: string;

  public screenType = ScreenTypes.CONTENT_CREATOR_GPT;

  public triggerTextareaResize = new EventEmitter();
  public isLoading = signal(false);
  public isDialogMode = signal(false);
  public textFromTimelineItemsProgress = signal(0);

  public lastSelectedText = signal<string>('');
  private hasTodayMessages = toSignal(this.chatMessagesFacade.chatHasTodayMessages$);
  public chatTimeLineItems = toSignal(this.chatTimelineService.chatTimeLineItems$);
  public currentChatMessages = toSignal(this.chatMessagesFacade.chatMessages$);

  public isReasoning = false;
  @ViewChild('promptTextarea') promptTextarea!: ElementRef;
  completionTextarea = viewChild<ElementRef<HTMLTextAreaElement>>('completionTextarea');

  public crmCardViewItem: Signal<CrmCardViewItem> = this.chatsFacadeService.selectedState.itemCrmCard;

  ngOnInit(): void {
    const textParts = this.initialMessage?.text.split('{{{prompt:}}}');
    if (textParts) {
      if (textParts[0]) {
        this.initialText.set(textParts[0]);
      }
      if (textParts[1]) {
        this.prompt = textParts[1];
      }
    }

    this.isContentCreatorInitialized$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isInitialized => {
      if (isInitialized) {
        this.promptTextarea.nativeElement.focus();
      }
    });
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const isFocusedOnPrompt = document.activeElement === this.promptTextarea.nativeElement;
    if (isFocusedOnPrompt && (event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      this.generateCompletion();
    }
  }

  setPrompt(promptVariant: AiGptPrompt): void {
    this.prompt = promptVariant.text || promptVariant.name;
    this.triggerTextareaResize.emit();
  }

  setTextFromTimelineItems(): void {
    this.isDialogMode.set(true);
    this.textFromTimelineItemsProgress.set(1);

    // Прежде чем начать транскрибирование, надо сначала собрать все сообщения
    this.chatTimelineService
      .loadDealFirstTimelineItem()
      .pipe(
        filter(item => item !== null),
        switchMap(dealFirstTimelineItem => {
          const timelineItems = [
            ...this.currentChatMessages()
              .map(message => this.chatTimelineService.createChatTimeLineItemByWhatsappMessage(message))
              .reverse(),
            ...this.chatTimeLineItems(),
          ];
          if (dealFirstTimelineItem !== 'not found') {
            // Удаляем все элементы, которые идут после первого элемента сделки
            const index = timelineItems.findIndex(item => item === dealFirstTimelineItem);
            timelineItems.splice(index + 1);
          }
          return this.dialogueTranscriptService.transcribeDialogue(timelineItems);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(transcribeEvent => {
        this.textFromTimelineItemsProgress.set(transcribeEvent.progress);
        if (transcribeEvent.progress === 100) {
          const textParts = [];
          const client = this.crmCardViewItem();

          if (client) {
            if (client.card) {
              const card = client.card;
              const clientInfo =
                'Информация о клиенте:\n' +
                'Имя - ' +
                (card.name || 'не указано') +
                '\n' +
                (card.info ? 'Последняя заметка менеджера о клиенте:\n' + card.info + '\n' : '');
              textParts.push(clientInfo.trim());
            }

            if (client.tourPackages && client.tourPackages.length > 0) {
              const tpList = [...client.tourPackages].reverse();
              const allTpInfo =
                'Информация о прошлых купленных турпакетах клиента:\nКоличество турпакетов - ' +
                tpList.length +
                '\n';
              const tpParts = [];
              let tpNumber = 1;
              for (const tp of tpList) {
                if (!tp.tours.length) {
                  continue;
                }
                const tpInfo =
                  'Турпакет ' +
                  tpNumber +
                  ' - с ' +
                  tp.dateFrom +
                  ' по ' +
                  tp.dateTo +
                  ', на ' +
                  (tp.days - 1) +
                  ' ' +
                  pluralize(tp.days - 1, ['ночь', 'ночи', 'ночей']) +
                  ', цена ' +
                  (tp.totalCostMoney.value + ' ' + tp.totalCostMoney.currency) +
                  '\nТуры в турпакете: \n';
                const tourParts = [];
                for (const tour of tp.tours) {
                  const tourInfo =
                    '- ' +
                    tour.country.name +
                    ' (' +
                    tour.region.name +
                    '), с ' +
                    tour.checkInDate +
                    ' по ' +
                    tour.checkOutDate +
                    ' на ' +
                    tour.nights +
                    pluralize(tour.nights, [' ночь', ' ночи', ' ночей']) +
                    (tour.adult
                      ? ', ' + tour.adult + pluralize(tour.adult, [' взрослый', ' взрослых', ' взрослых'])
                      : '') +
                    (tour.child
                      ? ', ' + tour.child + pluralize(tour.child, [' ребенок', ' ребенка', ' детей'])
                      : '') +
                    ', отель: ' +
                    tour.hotel.name +
                    ', питание: ' +
                    tour.meal.name +
                    ', номер: ' +
                    tour.roomType.name +
                    ', размещение : ' +
                    tour.room.name;
                  tourParts.push(tourInfo.trim());
                }
                const flyParts = [];
                for (const flight of tp.flights) {
                  const flightInfo = flight.departure.airport + ' - ' + flight.arrival.airport;
                  flyParts.push(flightInfo);
                }
                tpParts.push(
                  tpInfo +
                    tourParts.join(';\n') +
                    (flyParts.length ? '\nПерелеты в турпакете: ' + flyParts.join(', ') : ''),
                );
                tpNumber++;
              }
              textParts.push(allTpInfo + tpParts.join(';\n'));
            }
          }

          textParts.push(
            'История общения менеджера с клиентом: \n\n' +
              (transcribeEvent.dialogue || 'Текст в чате не распознан'),
          );
          this.initialText.set(textParts.join('\n\n\n\n'));
        }
      });
  }

  generateCompletion(): void {
    if (!this.prompt) {
      alert('Заполните поле "Что нужно сделать?"');
      return;
    }
    this.isLoading.set(true);
    this.completionText = '';
    const worker = this.workerService.currentWorkerValue;
    const promptMessages: AiGptMessage[] = [
      {
        role: 'system' as AiGptMessageRoleEnum,
        content:
          'Ты менеджер турагентства. ' +
          (worker.gender === 1 ? 'Ты парень. ' : 'Ты девушка. ') +
          'Тебя зовут ' +
          worker.name +
          '. ' +
          // 'Отвечай кратко. ' +
          (this.hasTodayMessages()
            ? 'Не здоровайся, если не поздоровались с тобой'
            : 'Обязательно поздоровайся') +
          '. ' +
          'Не говори "спасибо за обращение" и т.п. ' +
          'Сегодня ' +
          new Date().toLocaleDateString('ru-RU'),
      },
    ];
    if (this.initialText()) {
      promptMessages.push({
        role: 'user' as AiGptMessageRoleEnum,
        content: this.initialText(),
        name: this.initialMessage && !this.initialMessage.isFromMe ? 'client' : 'manager',
      });
    }
    promptMessages.push({
      role: 'system' as AiGptMessageRoleEnum,
      content: this.prompt,
    });
    this.aiGptApiService
      .complete(
        {
          messages: promptMessages,
          model: (this.isReasoning ? 'o3-mini' : 'gpt-4o') as AiGptCompleteRequestModelEnum,
          place: 'content-creator-gpt',
          maxTokens: this.isReasoning ? 5000 : 1000,
        },
        this.prompt,
      )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => {
          this.isLoading.set(false);
          console.error(err);
          alert('Ошибка при генерации текста');
          return of(null);
        }),
      )
      .subscribe(completion => {
        if (completion) {
          this.completionText = completion.messages[0].content;
          // Если в конце есть точка, то ее надо удалить
          if (this.completionText.endsWith('.')) {
            this.completionText = this.completionText.slice(0, -1);
          }
          this.isLoading.set(false);
          // Прокручиваем до textarea с результатом генерации
          setTimeout(() => {
            this.completionTextarea().nativeElement.scrollIntoView({ behavior: 'smooth' });
          }, 200);
        }
      });
  }

  sendContent(): void {
    this.sendContentEvent.emit();
  }

  sendSelectedText(): void {
    this.sendMessageEvent.emit({
      text: this.lastSelectedText(),
    });
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    if (!this.completionText) {
      return of([] as ContentCreatorItemMessage[]);
    }
    return of([
      {
        component: this,
        message: { text: this.completionText },
      },
    ]);
  }
}
