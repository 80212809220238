import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthService, WorkerStateService } from '../../core/services';
import { BrxButtonComponent } from '../brx/button/brx-button.component';
import { BrxIconComponent } from '../brx/icon/brx-icon.component';

@Component({
  selector: 'app-proxy-auth-frame',
  templateUrl: './proxy-auth-frame.component.html',
  styleUrl: './proxy-auth-frame.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BrxIconComponent, BrxButtonComponent, MatTooltip],
})
export class ProxyAuthFrameComponent {
  private readonly workerStateService = inject(WorkerStateService);
  private readonly authService = inject(AuthService);

  public currentWorker = this.workerStateService.currentWorkerValue;
  public isLoading = signal(false);

  logoutProxy() {
    this.isLoading.set(true);
    this.authService.logoutProxy();
  }
}
