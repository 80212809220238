<div class="worker" [attr.worker-id]="worker()?.id" [attr.worker-username]="worker()?.username">
  <div>
    <span class="worker-status worker-status--{{ worker()?.state }}"></span>
    <span class="worker-name" [innerHTML]="worker()?.name | highlight: highlight()"> </span>

    @if (worker()?.sip?.innerPhone) {
      <span class="worker-inner-phone"
        >(<span [innerHTML]="'' + worker().sip.innerPhone | highlight: highlight()"></span>)</span
      >
    }
  </div>

  <div class="control-buttons">
    @if (isCurrentWorker()) {
      <div matTooltip="Вы авторизованы под этим пользователем">
        <app-checkbox-rounded class="non-clickable" [isChecked]="true" />
      </div>
    } @else {
      <div class="proxy-login-wrapper">
        @if (isAllowedLoginProxy()) {
          @if (isLoginProxyLoading()) {
            <brx-loader [size]="16" [isDelayed]="false" />
          } @else {
            <a (click)="loginProxyClick.emit()" matTooltip="Посмотреть действия пользователя">
              <brx-icon name="@brx.arrow-right-circle" />
            </a>
          }
        }
      </div>
    }

    @if (worker().crmCardId) {
      <a
        (click)="sendMessageClick.emit()"
        matTooltip="Отправить сообщение"
        [attr.id]="isShowIntro() ? 'introWorkerListCard' : null"
      >
        <brx-icon name="@brx.send-message" />
      </a>
    }
  </div>
</div>
