import { Injectable, inject } from '@angular/core';
import {
  FindOrCreateAudioTranscriptRequestTypeEnum,
  FindOrCreateAudioTranscriptRequest,
} from '@api-clients/crm-api-client/dist/models';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, map, scan, startWith } from 'rxjs/operators';
import { ScreenTypes } from '../../../core/services/amplitude/amplitudeEventData';
import { AudioTranscriptService } from '../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/services/audio-transcript.service';
import {
  ChatTimeLineItem,
  ChatTimelineItemTypeEnum,
  WhatsappMessageTypeList,
} from '../interfaces/chat-timeline.interface';

export interface DialogueTranscriptProgress {
  total: number; // Общее количество элементов (асинхронных)
  completed: number; // Количество завершённых операций (асинхронных)
  progress: number; // Прогресс в процентах
  dialogue?: string; // Итоговый диалог после обработки всех элементов
}

@Injectable({
  providedIn: 'root',
})
export class DialogueTranscriptService {
  private audioTranscriptService = inject(AudioTranscriptService);
  private allowedChatMessageTypes = [
    WhatsappMessageTypeList.Chat,
    WhatsappMessageTypeList.Ptt,
    WhatsappMessageTypeList.Image,
    WhatsappMessageTypeList.ImageGroup,
    WhatsappMessageTypeList.Video,
    WhatsappMessageTypeList.Document,
  ];

  /**
   * Обрабатывает массив timeline-элементов, транскрибируя их в текст с ограничением
   * не более 3-х параллельных операций, и возвращает Observable с информацией о прогрессе.
   */
  transcribeDialogue(items: ChatTimeLineItem[]): Observable<DialogueTranscriptProgress> {
    // Вычисляем общее число асинхронных элементов (например, аудио и звонок)
    const asyncItems = items.filter(item => this.isAsyncItem(item));
    const totalAsync = asyncItems.length;
    // Если есть асинхронные задачи, начальный прогресс будет 1
    const initialProgress = totalAsync ? 1 : 95;

    return from(items.map((item, index) => ({ item, index }))).pipe(
      // Ограничиваем количество параллельных вызовов до 5
      mergeMap(
        ({ item, index }) =>
          this.getItemText(item).pipe(
            map(text => ({
              index,
              formatted: this.formatDialogueLine(item, text),
              isAsync: this.isAsyncItem(item),
            })),
            catchError(() =>
              of({
                index,
                formatted: this.formatDialogueLine(item, '[Не удалось получить текст сообщения]'),
                isAsync: this.isAsyncItem(item),
              }),
            ),
          ),
        5, // максимальное количество параллельных операций
      ),
      // Используем scan для накопления промежуточного состояния (прогресса)
      scan(
        (acc, curr) => {
          acc.results.push(curr);
          if (curr.isAsync) {
            acc.completed++;
          }
          acc.progress = totalAsync ? Math.max((acc.completed / totalAsync) * 100, 1) : 100;
          return acc;
        },
        {
          completed: 0,
          results: [] as Array<{ index: number; formatted: string; isAsync: boolean }>,
          progress: initialProgress,
        },
      ),
      // Преобразуем накопленный результат в объект прогресса
      // Поле dialogue передаётся только когда все элементы обработаны
      map(acc => {
        if (acc.results.length === items.length) {
          // Сортируем результаты по исходному порядку элементов в обратном порядке (от последнего к первому)
          const sortedResults = [...acc.results].sort((a, b) => b.index - a.index);
          const dialogue = sortedResults
            .map(result => result.formatted)
            .filter(text => text)
            .join('\n\n');
          return { total: totalAsync, completed: acc.completed, progress: acc.progress, dialogue };
        }
        return { total: totalAsync, completed: acc.completed, progress: acc.progress };
      }),
      // Отдаем начальное значение прогресса
      startWith({ total: totalAsync, completed: 0, progress: initialProgress }),
    );
  }

  private isProcessableItem(item: ChatTimeLineItem): boolean {
    if (item.type === ChatTimelineItemTypeEnum.message) {
      return this.allowedChatMessageTypes.includes(item.data?.type as WhatsappMessageTypeList);
    }
    return this.isAsyncItem(item);
  }

  private isAsyncItem(item: ChatTimeLineItem): boolean {
    return (
      (item.type === ChatTimelineItemTypeEnum.message && item.data?.type === WhatsappMessageTypeList.Ptt) ||
      item.type === ChatTimelineItemTypeEnum.call
    );
  }

  /**
   * Для данного timeline-элемента возвращает Observable с текстом.
   * Если у элемента уже есть текст, возвращает его, иначе вызывает транскрипцию (для аудио)
   */
  private getItemText(item: ChatTimeLineItem): Observable<string> {
    if (!this.isProcessableItem(item)) {
      return of('');
    }

    // Пока async-элементы у нас это только аудио, потом можно будет добавить картинки и видео
    // и распознавать что на них изображено
    if (this.isAsyncItem(item)) {
      const request: FindOrCreateAudioTranscriptRequest = {
        itemId: item.data?.id,
        type:
          item.type === ChatTimelineItemTypeEnum.call
            ? FindOrCreateAudioTranscriptRequestTypeEnum.Call
            : FindOrCreateAudioTranscriptRequestTypeEnum.WhatsappAudio,
        fromPlace: ScreenTypes.CONTENT_CREATOR_GPT,
      };
      let audioType = 'Голосовое сообщение';
      if (item.type === ChatTimelineItemTypeEnum.call) {
        audioType = 'Звонок ' + (item.data.callType === 0 ? 'менеджеру' : 'клиенту');
      }

      return this.audioTranscriptService
        .findOrCreateAudioTranscript(request)
        .pipe(map(response => audioType + ': ' + response.text));
    }

    if (item.type === ChatTimelineItemTypeEnum.message) {
      const message = item.data;
      switch (message?.type) {
        case WhatsappMessageTypeList.Image:
          return of('[Изображение]');
        case WhatsappMessageTypeList.ImageGroup:
          return of('[Группа изображений - ' + message?.images.length + ' шт.]');
        case WhatsappMessageTypeList.Video:
          return of('[Видео]');
        case WhatsappMessageTypeList.Document:
          return of('[Документ: ' + message?.media.fileName + ']');
      }
    }

    if (item.data?.text) {
      return of(item.data.text as string);
    }

    return of('');
  }

  /**
   * Форматирует одну строку диалога по шаблону:
   * "2024-01-02 15:00, менеджер:\n> Добрый день!"
   */
  private formatDialogueLine(item: ChatTimeLineItem, text: string): string {
    if (!text) {
      return '';
    }
    let sender = item.data && item.data.isFromMe ? 'менеджер' : 'клиент';
    if (item.type === ChatTimelineItemTypeEnum.call) {
      sender = 'менеджер и клиент';
    }

    return `${item.sortDateTime}, ${sender}:\n> ${text}`;
  }
}
