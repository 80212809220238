import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  signal,
  viewChild,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import {
  ChatSendMessageRequest,
  PhoneItem,
  WhatsappMessage,
  WhatsappNewMessage,
} from '@api-clients/crm-api-client';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { concatMap, map, reduce, takeUntil } from 'rxjs/operators';
import { ChatMessagesFacade } from '../../+state/chat-messages/chat-messages.facade';
import { ChatMessagesState } from '../../+state/chat-messages/chat-messages.state';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../core/services/amplitude/amplitudeEventData';
import { CONTENT_CREATOR_OPEN } from '../../core/services/amplitude/amplitudeEvents';
import { ChatMessageQueueService } from '../../core/services/chat/chat-message-queue.service';
import { ChatService } from '../../core/services/chat/chat.service';
import { getPhoneAsStringFromChatId } from '../../helpers/chat-fn';
import { insertTextAtCaretPosition } from '../../helpers/textarea';
import { ResizableTextareaDirective } from '../../shared/directives/resizable-textarea.directive';
import { SpeechRecognitionDirective } from '../../shared/directives/speech-recognition.directive';
import { NotifyService, NotifyTypeEnum } from '../../shared/notify/services/notify.service';
import { PopupService } from '../../shared/services/popup-service.service';
import { BrxAlertLabelComponent } from '../../ui-components/brx/alert-label/brx-alert-label.component';
import { BrxButtonComponent } from '../../ui-components/brx/button/brx-button.component';
import { BrxModalHeaderComponent } from '../../ui-components/brx/modal-header/brx-modal-header.component';
import { BrxLoaderFullscreenComponent } from '../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';
import { BrxScrollableTargetDirective } from '../../ui-components/brx/scrollable-wrapper/brx-scrollable-target.directive';
import { BrxScrollableWrapperComponent } from '../../ui-components/brx/scrollable-wrapper/brx-scrollable-wrapper.component';
import {
  ChatTimeLineItem,
  ChatTimelineItemTypeEnum,
} from '../chat-timeline/interfaces/chat-timeline.interface';
import { ContentCreatorItemWrapperComponent } from './components/content-creator-item-wrapper-component/content-creator-item-wrapper.component';
import { AudioPresentationComponent } from './components/items/audio-presentation/audio-presentation.component';
import {
  ApiContentType,
  ContentCreatorContentType,
  ContentCreatorItemMessage,
  ContentCreatorManagerOffer,
  ContentCreatorManagerOfferCardProps,
} from './interfaces/content-creator.interface';
import { ContentCreatorApiService } from './services/content-creator-api.service';
import { ContentCreatorModalControlService } from './services/content-creator-modal-control.service';

@Component({
  selector: 'app-content-creator',
  templateUrl: './content-creator.component.html',
  styleUrls: ['./content-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BrxLoaderFullscreenComponent,
    AsyncPipe,
    FormsModule,
    BrxAlertLabelComponent,
    MatInput,
    ResizableTextareaDirective,
    TranslateModule,
    BrxModalHeaderComponent,
    SpeechRecognitionDirective,
    BrxButtonComponent,
    BrxScrollableWrapperComponent,
    BrxScrollableTargetDirective,
    CdkDrag,
    CdkDragHandle,
  ],
  providers: [TranslatePipe],
})
export class ContentCreatorComponent implements AfterViewInit, OnDestroy {
  private readonly translatePipe = inject(TranslatePipe);
  public readonly dialogData: {
    chatTimeLineItem: ChatTimeLineItem | null;
    initialContentTypes: ContentCreatorContentType[] | null;
    initialContentPlaceholderTypes: ContentCreatorContentType[] | null;
    isOpenedFromPlaceholder: boolean;
    tour?: ContentCreatorManagerOffer;
  } = inject(MAT_DIALOG_DATA);
  private readonly popupService = inject(PopupService);
  public readonly dialogRef =
    inject<MatDialogRef<ContentCreatorComponent, Observable<ChatSendMessageRequest[]>>>(MatDialogRef);
  private readonly contentCreatorModalControlService = inject(ContentCreatorModalControlService);
  private readonly contentCreatorApiService = inject(ContentCreatorApiService);
  private readonly chatService = inject(ChatService);
  private readonly chatMessageQueueService = inject(ChatMessageQueueService);
  private readonly chatMessagesFacade = inject(ChatMessagesFacade);
  private readonly notifyService = inject(NotifyService);
  private readonly amplitudeTrackService = inject(AmplitudeTrackService);
  private readonly cdr = inject(ChangeDetectorRef);

  /**
   * Текущий номер телефона в активном чате
   */
  private currentPhoneItem: PhoneItem;

  /**
   * Объект сообщения в таймлайне клиента
   */
  public chatTimeLineItem: ChatTimeLineItem | null;

  /**
   * Типы контента, которые нужно добавить в модальное окно
   */
  public initialContentTypes: ContentCreatorContentType[] | null;

  /**
   * Типы контента, которые будет предложено добавить в модальное окно
   */
  public initialContentPlaceholderTypes: ContentCreatorContentType[] | null;

  /**
   * Сообщение WhatsApp через которое открыли ContentCreator
   */
  public initialWhatsappMessage: WhatsappMessage | null = null;

  /**
   * Заголовок модального окна
   */
  public header = '';

  /**
   * ID тура, для которого создается контент
   */
  public tourId = '';

  /**
   * Данные для формирования контента по туру
   */
  public tourContent: TourContent;

  /**
   * Название отеля, для которого создается контент
   */
  public hotelName$ = new BehaviorSubject('');

  private contentItemWrapperComponents: ContentCreatorItemWrapperComponent[] = [];

  /**
   * Текст сообщения, для случая, когда добавляется только один тип контента
   */
  public singleMessageText = '';

  /**
   * Флаг, указывающий на то, что в модальное окно добавляется только один тип контента
   */
  public isSingleContent = signal(false);

  /**
   * Будет ло показано поле для ввода сообщения в футере
   */
  public isSingleMessageInputVisible = false;

  /**
   * Модальное окно открыто из плейсхолдера в другом создателе контента.
   * Будет работать по другому. И при отправке сообщения не будут отправляться,
   * а будут переданы в родительский компонент
   */
  public isOpenedFromPlaceholder = false;
  public tour: ContentCreatorManagerOffer;

  /**
   * Будет ло показана только кнопка для отправки сообщения (без поля ввода текста)
   */
  public isPlainSendButtonVisible = false;

  public isLoading$ = new BehaviorSubject(true);
  public errorMessage$ = new BehaviorSubject<string | null>(null);
  public isContentCreatorWrapperVisible$ = combineLatest([this.isLoading$, this.errorMessage$]).pipe(
    map(([isLoading, errorMessage]) => !isLoading && errorMessage === null),
  );
  private isContentCreatorInitializedSubject = new BehaviorSubject(false);
  public isContentCreatorInitialized$ = this.isContentCreatorInitializedSubject.asObservable();

  private popupOpenSource = toSignal(this.popupService.popupOpenSource);
  private isOtherPopupOpened = computed(() => this.popupOpenSource().isOpen);

  private destroy$ = new Subject<void>();

  public singleMessageTextarea = viewChild<ElementRef<HTMLTextAreaElement>>('singleMessageTextarea');

  @ViewChild('scrollableWrapper', { read: ViewContainerRef })
  scrollableWrapper: ViewContainerRef;

  @ViewChild('itemWrapperComponentsPlace', { read: ViewContainerRef })
  itemWrapperComponentsPlace: ViewContainerRef;

  public screenType = ScreenTypes.CONTENT_CREATOR;

  constructor() {
    this.contentCreatorModalControlService.addOpenedDialog(this.dialogRef);

    /**
     * @see HotOffersShortOffersModalComponent - тоже подписка, логика должна быть одна
     */
    this.chatMessagesFacade.chatMessagesState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((messagesState: ChatMessagesState) => {
        if (messagesState.contactPhoneItemOrChatId) {
          // в группах фич из content-creator должны быть отключены...
          this.currentPhoneItem = messagesState.contactPhoneItemOrChatId as PhoneItem;
        }
      });
    // Когда модалка откроется, то будем считать, что компонент полностью инициализирован
    this.dialogRef
      .afterOpened()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this.isContentCreatorInitializedSubject.next(true), 0);
      });
    // Т.к. у модалки отключено закрытие по клику на бэкдроп, то нужно добавить этот обработчик самим
    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closeModal();
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // Нельзя закрывать модальное окно,
      // если открыта галерея изображений хоть в одном из дочерних компонентов
      if (
        this.contentItemWrapperComponents.some(
          componentWrapper => componentWrapper?.itemComponent?.isGalleryOpen,
        )
      ) {
        return;
      }
      this.closeModal();
    }
  }

  private toSnakeCase(value: string): string {
    return value.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);
  }

  private showTourNotFoundError(): void {
    this.errorMessage$.next(
      `${this.translatePipe.transform('PAGES.CONTENT_CREATOR.TOUR_NOT_FOUND')} ${this.tourId}`,
    );
    this.isLoading$.next(false);
    this.trackContentCreatorOpen(false);
  }

  addContentItemsByTourId(): void {
    if (this.tourId) {
      const dataForLoad = [ApiContentType.TourTextMessageData];
      this.initialContentTypes.forEach(type => {
        switch (type) {
          case ContentCreatorContentType.AudioPresentation:
            dataForLoad.push(ApiContentType.HotelAudioPresentations);
            break;
          case ContentCreatorContentType.Photos:
            dataForLoad.push(ApiContentType.HotelPhotos);
            break;
          case ContentCreatorContentType.Videos:
            dataForLoad.push(ApiContentType.HotelVideos);
            break;
          case ContentCreatorContentType.ManagerOffer:
            dataForLoad.push(ApiContentType.ManagerOffer);
            break;
          case ContentCreatorContentType.ManagerComments:
            dataForLoad.push(ApiContentType.HotelManagerComments);
            break;
          case ContentCreatorContentType.Reviews:
            dataForLoad.push(ApiContentType.HotelReviews);
            break;
        }
      });

      // Загружаем данные тура из API
      const tour = this.tour ? this.tour : { tourId: this.tourId };
      this.contentCreatorApiService
        .getTourContent(tour, dataForLoad, this.getCurrentCrmCardProps())
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: tourContent => {
            this.tourContent = tourContent;
            this.hotelName$.next(
              `${tourContent.tourMessageData.hotel.name}, ${tourContent.tourMessageData.region}`,
            );
            this.isLoading$.next(false);
            // После загрузки данных тура добавляем компоненты для создания контента
            if (this.initialContentTypes) {
              this.initialContentTypes.forEach(type => {
                let isEnabled = true;
                // Если отель был отправлен раньше, то не надо выделять фото, видео и аудио-презентации
                if (
                  tourContent?.offer?.isHotelSentBefore &&
                  [
                    ContentCreatorContentType.Videos,
                    ContentCreatorContentType.Photos,
                    ContentCreatorContentType.AudioPresentation,
                  ].includes(type)
                ) {
                  isEnabled = false;
                }
                this.addContent(type, false, isEnabled);
              });
            }
            if (this.initialContentPlaceholderTypes) {
              this.initialContentPlaceholderTypes.forEach(type => this.addContent(type, true));
            }
            this.trackContentCreatorOpen(true);
          },
          error: error => {
            console.error('error: ', error);
            this.showTourNotFoundError();
          },
        });
    } else {
      this.showTourNotFoundError();
    }
  }

  ngAfterViewInit(): void {
    this.chatTimeLineItem = this.dialogData.chatTimeLineItem;
    this.initialContentTypes = this.dialogData.initialContentTypes;
    this.initialContentPlaceholderTypes = this.dialogData.initialContentPlaceholderTypes;
    this.isOpenedFromPlaceholder = this.dialogData.isOpenedFromPlaceholder;
    this.tour = this.dialogData.tour;

    if (
      this.chatTimeLineItem &&
      this.chatTimeLineItem.type === ChatTimelineItemTypeEnum.message &&
      this.chatTimeLineItem?.data
    ) {
      this.initialWhatsappMessage = this.chatTimeLineItem.data;
    }

    if (this.initialWhatsappMessage && this.initialWhatsappMessage.text) {
      // Ищем номер тура в тексте сообщения
      const tourIdMatch = this.initialWhatsappMessage.text.match(/номер тура: ([a-zA-Z0-9-]+)/);
      if (tourIdMatch) {
        [, this.tourId] = tourIdMatch;
      }
    }

    const allContentTypes = (this.initialContentTypes || []).concat(
      this.initialContentPlaceholderTypes || [],
    );
    this.isSingleContent.set(allContentTypes.length === 1);
    // Без этого не будут отрабатывать компоненты, которым не нужны дополнительные данные для загрузки
    this.cdr.detectChanges();

    // Для определенных типов контента надо подгружать данные из апи
    const isNeedTourDataTypes = [
      ContentCreatorContentType.PricesCalendar,
      ContentCreatorContentType.Photos,
      ContentCreatorContentType.Videos,
      ContentCreatorContentType.AudioPresentation,
      ContentCreatorContentType.ManagerOffer,
      ContentCreatorContentType.ManagerComments,
      ContentCreatorContentType.Reviews,
    ];
    const isNeedTourData = this.initialContentTypes.some(type => isNeedTourDataTypes.includes(type));
    if (isNeedTourData) {
      this.addContentItemsByTourId();
    } else {
      this.initialContentTypes.forEach(type => this.addContent(type));
      if (this.initialContentPlaceholderTypes) {
        this.initialContentPlaceholderTypes.forEach(type => this.addContent(type, true));
      }
      this.isLoading$.next(false);
      this.trackContentCreatorOpen(true);
    }
    this.cdr.detectChanges();

    // Если передали изначально какой тип контента хотят отправить, то это будет единственный тип контента
    if (this.isSingleContent()) {
      this.isSingleMessageInputVisible =
        [ContentCreatorContentType.Gpt].indexOf(this.initialContentTypes[0]) === -1;
      this.header = this.translatePipe.transform(
        `PAGES.CONTENT_CREATOR.CONTENT_TYPE.${this.toSnakeCase(this.initialContentTypes[0]).toUpperCase()}`,
      );
    } else {
      this.header = this.translatePipe.transform('PAGES.CONTENT_CREATOR.DEFAULT_HEADER');
    }
  }

  private trackContentCreatorOpen(isTourFound: boolean): void {
    this.amplitudeTrackService.trackEvent(CONTENT_CREATOR_OPEN, {
      'Screen type': ScreenTypes.CHAT,
      'Initial content type': this.initialContentTypes ? this.initialContentTypes.join(',') : null,
      'Tour ID': this.tourId,
      'Is tour found': isTourFound,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Добавляет компоненты определенного типа в модальное окно
   * @param type - тип контента
   * @param isPlaceholder - компонент является плейсхолдером для дальнейшего
   * @param isEnabled - компонент включен
   * добавления полноценного компонента через новое модальное окно
   */
  addContent(type: ContentCreatorContentType, isPlaceholder = false, isEnabled = true): void {
    const itemWrapperComponentRef = this.itemWrapperComponentsPlace.createComponent(
      ContentCreatorItemWrapperComponent,
    );
    const isMultipleMode = !this.isSingleContent();
    itemWrapperComponentRef.instance.itemType = type;
    itemWrapperComponentRef.instance.tourId = this.tourId;
    itemWrapperComponentRef.instance.isMultipleMode = isMultipleMode;
    itemWrapperComponentRef.instance.isPlaceholder = isPlaceholder;
    itemWrapperComponentRef.instance.isContentCreatorInitialized$ = this.isContentCreatorInitialized$;
    itemWrapperComponentRef.instance.isEnabled = isEnabled;

    this.isPlainSendButtonVisible = isMultipleMode;
    itemWrapperComponentRef.instance
      .createItemComponent(this.tourContent, this.initialWhatsappMessage)
      .pipe(takeUntil(this.destroy$))
      .subscribe(itemComponentRef => {
        if (itemComponentRef?.instance?.insertSingleMessageText) {
          itemComponentRef.instance.insertSingleMessageText.pipe(takeUntil(this.destroy$)).subscribe(text => {
            insertTextAtCaretPosition(this.singleMessageTextarea().nativeElement, text, true, true);
          });
        }
        if (itemComponentRef?.instance?.sendContentEvent) {
          itemComponentRef.instance.sendContentEvent
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.sendContent());
        }
        if (itemComponentRef?.instance?.sendMessageEvent) {
          itemComponentRef.instance.sendMessageEvent
            .pipe(takeUntil(this.destroy$))
            .subscribe(message => this.sendMessage(message));
        }
      });

    this.contentItemWrapperComponents.push(itemWrapperComponentRef.instance);
  }

  /**
   * Сообщение состоит только из текста, без медиа
   */
  private isTextMessage(message: Partial<ChatSendMessageRequest>): boolean {
    return message.text && !message.fileContent && !message.fileUrl;
  }

  /**
   * Приклеивает все текстовые сообщение в одно (первое текстовое в массиве)
   */
  private mergeTextMessages(messages: Partial<ChatSendMessageRequest>[]): Partial<ChatSendMessageRequest>[] {
    const firstTextMessageIndex = messages.findIndex(message => this.isTextMessage(message));
    if (firstTextMessageIndex === -1) {
      return messages;
    } else {
      messages[firstTextMessageIndex].text = messages[firstTextMessageIndex].text.trim();
    }
    // Удаляем все текстовые сообщения, кроме первого
    const mergedMessages: Partial<ChatSendMessageRequest>[] = [];
    for (let i = 0; i < messages.length; i++) {
      const isTextMessage = this.isTextMessage(messages[i]);
      if (i === firstTextMessageIndex || !isTextMessage) {
        mergedMessages.push(messages[i]);
      } else if (isTextMessage) {
        // Если это текстовое сообщение, то добавляем его к первому
        mergedMessages[firstTextMessageIndex].text += `\n\n${messages[i].text.trim()}`;
      }
    }
    return mergedMessages;
  }

  /**
   * Отправляет весь контент в последовательных сообщениях
   */
  public sendContent(): void {
    this.isLoading$.next(true);
    const phone = `${this.currentPhoneItem.code}${this.currentPhoneItem.phone}`;
    const hasAudioPresentation = this.contentItemWrapperComponents.some(
      component => component instanceof AudioPresentationComponent,
    );
    // Показываем сообщение о том, что контент формируется
    let pendingMessage: WhatsappNewMessage = null;
    if (!this.isOpenedFromPlaceholder) {
      pendingMessage = this.chatService.showPendingMessage(
        {
          phoneOrChatId: phone,
          text:
            `Формируем контент для отправки на номер ${phone}` +
            `${hasAudioPresentation ? ', это может занять минутку, т.к. есть аудио-презентации' : ''}...`,
        },
        this.currentPhoneItem,
      );
    }
    // Нужно собрать все сообщения из компонентов в один последовательный поток
    const messages$ = from(this.contentItemWrapperComponents).pipe(
      concatMap(componentWrapper =>
        // Если блок включен, то запросим из него контент для отправки
        componentWrapper.isEnabled ? componentWrapper.itemComponent.getMessagesForSend() : of([]),
      ),
      // Соберем все сообщения для отправки в один плоский массив
      reduce((acc, itemMessages) => acc.concat(itemMessages), [] as ContentCreatorItemMessage[]),
      map((itemMessages: ContentCreatorItemMessage[]) => {
        // Контент сформирован, можно удалять сообщение с информацией, что контент формируется
        if (pendingMessage) {
          this.chatService.deletePendingMessage(pendingMessage);
        }
        // Достаем все сообщения из itemMessages
        let messages = itemMessages.map(itemMessage => itemMessage.message);
        // Если у нас всего одно сообщение и в нем пустой текст (т.е. это скорее всего файл)
        // и есть singleMessageText. То добавляем его в качестве подписи к нему текст из singleMessageText
        if (this.singleMessageText) {
          if (
            messages.length === 1 &&
            !messages[0].text &&
            // К аудио файлам нельзя прикреплять тексты
            !(itemMessages[0].component instanceof AudioPresentationComponent)
          ) {
            messages[0].text = this.singleMessageText;
          } else {
            messages.push({ text: this.singleMessageText });
          }
        }

        // Нам надо склеить все сообщения с текстом в одно сообщение,
        // чтобы не было разрывов и они отправились в одном сообщении.
        // Это нужно например для менеджерских отзывов и отзывов туристов
        // (чтобы они приклеивались к тексту подборки)
        messages = this.mergeTextMessages(messages);

        // Надо проставить всем сообщениям place и телефон
        messages.forEach(message => {
          message.place = this.screenType;
          message.phoneOrChatId = phone;
        });
        // Первое сообщение в группе должно цитировать
        // изначальное сообщение через которое открыли ContentCreator
        if (this.initialWhatsappMessage && messages.length) {
          messages[0].quotedMessageId = this.initialWhatsappMessage.id;
        }
        // Т.к. выше проставили place и phone, то мы точно знаем,
        // что тут больше не Partial<ChatSendMessageRequest>[]
        return messages as ChatSendMessageRequest[];
      }),
    );
    // Для компонентов открытых из родительских плейсхолдеров отправка не нужна.
    // Сообщения просто будут переданы в родительский компонент для дальнейшей работы с ними
    if (!this.isOpenedFromPlaceholder) {
      this.chatMessageQueueService.queueFromObservable(this.currentPhoneItem, messages$);
    }
    // Если есть открытые модалки, то возможно пользователь не увидит, что сообщение было отправлено в чат.
    // Нам надо будет показать уведомление, что сообщение отправлено
    if (!this.isOpenedFromPlaceholder && this.isOtherPopupOpened()) {
      this.notifyService.create({
        type: NotifyTypeEnum.success,
        title: 'Чат',
        text: 'Сообщение отправлено клиенту',
      });
    }
    if (this.tour?.sentMessageFn) {
      this.tour?.sentMessageFn.call(this);
    }

    this.closeModal(messages$);
  }

  private sendMessage(message: Partial<ChatSendMessageRequest>) {
    const phone = `${this.currentPhoneItem.code}${this.currentPhoneItem.phone}`;
    message.place = this.screenType;
    message.phoneOrChatId = phone;
    this.chatMessageQueueService.queueMessage(this.currentPhoneItem, message as ChatSendMessageRequest);
  }

  closeModal(messages$: Observable<ChatSendMessageRequest[]> = of([] as ChatSendMessageRequest[])): void {
    this.dialogRef.close(messages$);
  }

  public getCurrentCrmCardProps(): ContentCreatorManagerOfferCardProps | null {
    if (this.currentPhoneItem) {
      return {
        crmCardPhone: getPhoneAsStringFromChatId(this.currentPhoneItem),
      };
    }

    return null;
  }
}
