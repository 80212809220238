import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { AiGptPrompt } from '@api-clients/api-client/dist';
import { EMPTY, Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ScreenTypes } from '../../../../../../../core/services/amplitude/amplitudeEventData';
import { ResizableTextareaDirective } from '../../../../../../../shared/directives/resizable-textarea.directive';
import { SpeechRecognitionDirective } from '../../../../../../../shared/directives/speech-recognition.directive';
import { NotifyService } from '../../../../../../../shared/notify/services/notify.service';
import { BrxButtonComponent } from '../../../../../../../ui-components/brx/button/brx-button.component';
import { BrxLoaderFullscreenComponent } from '../../../../../../../ui-components/brx/loader-fullscreen/brx-loader-fullscreen.component';
import { BrxModalHeaderComponent } from '../../../../../../../ui-components/brx/modal-header/brx-modal-header.component';
import { AiGptPromptApiService } from '../../../../../services/ai-gpt-prompt-api.service';

@Component({
  selector: 'app-frontend-gpt-prompt-form',
  standalone: true,
  templateUrl: './gpt-prompt-form.component.html',
  styleUrl: './gpt-prompt-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BrxModalHeaderComponent,
    MatInput,
    ReactiveFormsModule,
    ResizableTextareaDirective,
    SpeechRecognitionDirective,
    FormsModule,
    BrxButtonComponent,
    BrxLoaderFullscreenComponent,
  ],
})
export class GptPromptFormComponent implements OnInit {
  public readonly dialogData: AiGptPrompt = inject(MAT_DIALOG_DATA);
  public readonly dialogRef = inject(MatDialogRef<GptPromptFormComponent, AiGptPrompt>);
  public readonly aiPromptApiService = inject(AiGptPromptApiService);
  public readonly notifyService = inject(NotifyService);
  public readonly destroyRef = inject(DestroyRef);

  public id: number;
  public name: string;
  public text: string;

  public isLoading = signal(false);

  public screenType = ScreenTypes.CONTENT_CREATOR_GPT_PROMPT;

  ngOnInit() {
    this.id = this.dialogData?.id;
    this.name = this.dialogData?.name;
    this.text = this.dialogData?.text;
  }

  savePrompt() {
    this.isLoading.set(true);
    let saveRequest: Observable<AiGptPrompt>;
    if (this.id) {
      saveRequest = this.aiPromptApiService.update(this.id, this.name, this.text);
    } else {
      saveRequest = this.aiPromptApiService.create(this.name, this.text);
    }
    saveRequest
      .pipe(
        first(),
        takeUntilDestroyed(this.destroyRef),
        catchError(() => {
          this.isLoading.set(false);
          this.notifyService.error('Не удалось сохранить промпт');
          return EMPTY;
        }),
      )
      .subscribe((prompt: AiGptPrompt) => {
        this.isLoading.set(false);
        this.dialogRef.close(prompt);
      });
  }

  removePrompt() {
    if (!confirm('Вы уверены, что хотите удалить промпт?')) {
      return;
    }
    this.isLoading.set(true);
    this.aiPromptApiService
      .delete(this.id)
      .pipe(
        first(),
        takeUntilDestroyed(this.destroyRef),
        catchError(() => {
          this.isLoading.set(false);
          this.notifyService.error('Не удалось удалить промпт');
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.isLoading.set(false);
        this.dialogData.name = 'deleted';
        this.dialogRef.close(this.dialogData);
      });
  }
}
