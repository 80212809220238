<div class="login-container">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
    <mat-form-field appearance="outline">
      <mat-label>Имя пользователя</mat-label>
      <input matInput formControlName="username" />
      @if (loginForm.get('username')?.invalid && loginForm.get('username')?.touched) {
        <mat-error>Имя пользователя обязательно</mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Пароль</mat-label>
      <input matInput type="password" formControlName="password" />
      @if (loginForm.get('password')?.invalid && loginForm.get('password')?.touched) {
        <mat-error> Пароль обязателен</mat-error>
      }
    </mat-form-field>

    @if (errorMessage()) {
      <mat-error class="error-message">
        {{ errorMessage() }}
      </mat-error>
    }

    <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid || isLoading()">
      Войти
    </button>
  </form>
</div>
