<brx-modal-header [dialogRef]="dialogRef" [text]="(this.id ? 'Редактирование' : 'Создание') + ' промпта'" />

@if (isLoading()) {
  <brx-loader-fullscreen />
}

<label>Короткое название промпта<br /> </label>
<input class="input" [(ngModel)]="name" placeholder="Введите название..." />

<label>Текст промпта</label>
<div>
  <textarea
    class="input"
    rows="1"
    spellcheck="true"
    appResizableTextarea
    [maxHeightInPx]="260"
    appSpeechRecognition
    [screenType]="screenType"
    [recorderButtonMarginBottom]="3"
    matInput
    [(ngModel)]="text"
    placeholder="Например: придумай продающий текст..."
  ></textarea>
</div>

<div class="save-controls">
  @if (this.id) {
    <button brx-button color="red" (click)="removePrompt()">Удалить</button>
  } @else {
    <div></div>
  }
  <button brx-button color="blue" (click)="savePrompt()">Сохранить</button>
</div>
