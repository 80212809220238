<div class="modal-managers-list">
  <div style="display: flex">
    <h2 style="margin-right: 1rem" class="modal-managers-list-title">
      {{ 'MODALS.MANAGERS_LIST.TITLE' | translate }}
    </h2>
    <brx-input-search placeholder="Поиск" (inputEvent)="onSearchQueryChange($event)" [focusOnInit]="true" />
  </div>
  <app-close-modal-button [dialogRef]="dialogRef" />
  @if (isLoading()) {
    <brx-loader-fullscreen />
  }
  @if (isProxyAuth) {
    <brx-alert-label type="warning" class="proxy-login-info">
      Вы авторизованы под пользователем {{ currentWorker.name }} ({{ currentWorker.username }})
      <button brx-button color="blue" (click)="logoutProxy()">Вернуться на свой аккаунт</button>
    </brx-alert-label>
  }
  @if (officeList().length) {
    <div class="managers-list-wrapper" [class.non-clickable]="proxyLoginLoadingUserId()">
      @for (office of officeList(); track office; let isFirstOffice = $first) {
        <div class="office-item">
          <h5 class="office-item-title">{{ office }}</h5>
          <div class="office-item-list">
            @for (worker of workersByOffice()[office]; track worker.id; let isFirstManager = $first) {
              <app-worker-name
                [worker]="worker"
                [isCurrentWorker]="currentWorker.id === worker.id"
                [highlight]="russianLayoutSearchQuery()"
                [isShowIntro]="isFirstOffice && isFirstManager"
                [isAllowedLoginProxy]="allowedProxyUserIds().has(worker.id)"
                [isLoginProxyLoading]="proxyLoginLoadingUserId() === worker.id"
                (sendMessageClick)="sendMessage(worker)"
                (loginProxyClick)="loginProxy(worker.id)"
              />
            }
          </div>
        </div>
      }
    </div>
    <div class="managers-status-legend">
      <h4 class="managers-status-legend-title">
        {{ 'MODALS.MANAGERS_LIST.MANAGER_STATUS.TITLE' | translate }}
      </h4>
      <ul class="status-legend-items">
        <li class="status-legend-item">
          <span class="managers-status managers-status--available"></span>
          <span class="status-legend-title">{{
            'MODALS.MANAGERS_LIST.MANAGER_STATUS.AVAILABLE' | translate
          }}</span>
        </li>
        <li class="status-legend-item">
          <span class="managers-status managers-status--busy"></span>
          <span class="status-legend-title">{{
            'MODALS.MANAGERS_LIST.MANAGER_STATUS.BUSY' | translate
          }}</span>
        </li>
        <li class="status-legend-item">
          <span class="managers-status managers-status--offline"></span>
          <span class="status-legend-title">{{
            'MODALS.MANAGERS_LIST.MANAGER_STATUS.OFFLINE' | translate
          }}</span>
        </li>
      </ul>
    </div>
  }
</div>
