import { Component, input, output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Worker } from '../../../../../../models';
import { HighlightPipe } from '../../../../../../shared/pipes/highlight.pipe';
import { BrxIconComponent } from '../../../../../../ui-components/brx/icon/brx-icon.component';
import { BrxLoaderComponent } from '../../../../../../ui-components/brx/loader/brx-loader.component';
import { CheckboxRoundedComponent } from '../../../../../../ui-components/checkbox-rounded/checkbox-rounded.component';

@Component({
  selector: 'app-worker-name',
  templateUrl: './worker-name.component.html',
  styleUrls: ['./worker-name.component.scss'],
  imports: [BrxIconComponent, BrxLoaderComponent, CheckboxRoundedComponent, HighlightPipe, MatTooltip],
})
export class WorkerNameComponent {
  worker = input<Worker>();
  highlight = input<string>();
  isCurrentWorker = input<boolean>(false);
  isLoginProxyLoading = input<boolean>();
  isAllowedLoginProxy = input<boolean>(false);
  isShowIntro = input<boolean>(false);

  sendMessageClick = output<void>();
  loginProxyClick = output<void>();
}
