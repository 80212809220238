import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of } from 'rxjs';
import { ScreenTypes } from '../../../../../core/services/amplitude/amplitudeEventData';
import { SpeechRecognitionDirective } from '../../../../../shared/directives/speech-recognition.directive';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { ResizableTextareaDirective } from '../../../../../shared/directives/resizable-textarea.directive';

@Component({
  selector: 'app-content-creator-manager-offer',
  templateUrl: './content-creator-manager-offer.component.html',
  styleUrls: ['./content-creator-manager-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, MatInputModule, ResizableTextareaDirective, SpeechRecognitionDirective],
})
export class ContentCreatorManagerOfferComponent implements OnInit, ContentCreatorItemComponent {
  @Input() tourId: string;
  @Input() tourContent: TourContent;

  public message: string;
  public screenType = ScreenTypes.CONTENT_CREATOR_MANAGER_OFFER;

  ngOnInit() {
    this.message = this.tourContent.offer.message;
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    return of([
      {
        component: this,
        message: { text: this.message },
      },
    ]);
  }
}
