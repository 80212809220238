@if (initialText()) {
  Исходный текст:
  <textarea
    rows="1"
    spellcheck="true"
    appResizableTextarea
    [maxHeightInPx]="260"
    matInput
    [(ngModel)]="initialText"
  ></textarea>
} @else {
  <div class="set-initial-text-wrapper">
    @if (textFromTimelineItemsProgress()) {
      <div>Идет загрузка и распознавание сообщений из чата...</div>
      <app-line-progress class="progress" [progress]="textFromTimelineItemsProgress()" />
    } @else {
      <button brx-button color="blue" iconName="@brx.arrow-in" (click)="setTextFromTimelineItems()">
        Загрузить сообщения из чата
      </button>
    }
  </div>
}

Что нужно сделать?
<div>
  <textarea
    [class.is-without-initial-message]="!initialMessage"
    #promptTextarea
    rows="1"
    spellcheck="true"
    appResizableTextarea
    [maxHeightInPx]="260"
    [triggerResize]="triggerTextareaResize"
    appSpeechRecognition
    [screenType]="screenType"
    [recorderButtonMarginBottom]="3"
    matInput
    [(ngModel)]="prompt"
    placeholder="Например: придумай продающий текст..."
  ></textarea>
</div>

<app-gpt-prompt-list [isDialogMode]="isDialogMode()" (setPrompt)="setPrompt($event)" />

<div class="generate-controls">
  <div class="right">
    <brx-checkbox [(ngModel)]="isReasoning" matTooltip="Будет использована размышляющая ИИ модель"
      >Подумать лучше</brx-checkbox
    >
  </div>
  <div class="right">
    <button
      brx-button
      iconName="@brx.ai"
      color="blue"
      [isLoading]="isLoading()"
      (click)="generateCompletion()"
    >
      Сгенерировать
    </button>
  </div>
</div>

@if (completionText) {
  Итоговый текст для отправки:
  <div class="generated-text-wrapper">
    <textarea
      #completionTextarea
      rows="1"
      spellcheck="true"
      appResizableTextarea
      [maxHeightInPx]="400"
      matInput
      [(ngModel)]="completionText"
      appTextSelection
      [appTextSelectionUseMousePos]="true"
      [appTextSelectionMenu]="textSelectionMenuTemplate"
      (lastSelectedText)="lastSelectedText.set($event)"
    ></textarea>
    <ng-template #textSelectionMenuTemplate>
      <app-speech-bubble (click)="sendSelectedText()" arrowPosition="none">
        <brx-icon name="@brx.send-message" /> Отправить сообщение
      </app-speech-bubble>
    </ng-template>
  </div>

  <div class="right">
    <button brx-button color="blue" iconName="@brx.send-message" (click)="sendContent()">Отправить</button>
  </div>
}
