import { ChangeDetectionStrategy, Component, inject, input, output, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ResizableTextareaDirective } from '../../../shared/directives/resizable-textarea.directive';
import { BrxAlertLabelComponent } from '../../../ui-components/brx/alert-label/brx-alert-label.component';
import { BrxBadgeComponent } from '../../../ui-components/brx/badge/brx-badge.component';
import { BrxButtonRemoveComponent } from '../../../ui-components/brx/button-remove/brx-button-remove.component';
import { BrxCheckboxComponent } from '../../../ui-components/brx/checkbox/brx-checkbox.component';
import { DreamMotivationListComponent } from '../components/dream-motivation-list/dream-motivation-list.component';
import { EditableEmployeeDream } from '../interfaces/dream.interface';

@Component({
  selector: 'app-dream-form',
  standalone: true,
  templateUrl: './dream-form.component.html',
  styleUrl: './dream-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BrxAlertLabelComponent,
    BrxBadgeComponent,
    BrxCheckboxComponent,
    MatInput,
    ReactiveFormsModule,
    ResizableTextareaDirective,
    FormsModule,
    BrxButtonRemoveComponent,
  ],
})
export class DreamFormComponent {
  private readonly matDialog = inject(MatDialog);

  public dream = input<EditableEmployeeDream>();
  public removeDream = output();

  public isDreamChanged = signal(false);

  setDreamChanged(): void {
    if (this.dream().id) {
      this.isDreamChanged.set(true);
    }
  }

  showMotivationsForDream(): void {
    this.matDialog.open(DreamMotivationListComponent, {
      panelClass: 'modal-panel-rounded',
      width: '750px',
      minHeight: '300px',
      maxHeight: '95vh',
      data: { dream: this.dream() },
    });
  }
}
