@if (isLoading()) {
  <brx-loader-fullscreen />
}
<iframe
  #frame
  [class.visible]="isCalendarFrameVisible()"
  class="calendar-frame"
  [src]="htmlCalendarUrl"
  (load)="onFrameLoad()"
></iframe>
@if (errorMessage()) {
  <brx-alert-label type="danger">
    {{ errorMessage() }}
  </brx-alert-label>
}
